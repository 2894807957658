<template>
	<div class="upgrade-container">
		<div class="row ">
			<div class="col-8">
				<div class="row">
					<div class="col-6">
						<h2 class="mb-3">Upgrades</h2>
					</div>
					<div class="col-6">
						<h2 class="mb-3"><span class="building-level">Tier {{ building.land.length }}</span></h2>
					</div>
				</div>
				
				<div class="row mt-2">
					<div class="col-3">
						<button v-on:click="triggerUpgrade(1,1)" :disabled="!ownsAllTiles(requiredUpgradeTiles(1,1))" class="btn btn-primary btn-view btn-sm">
							North East - {{ tileOwnership(requiredUpgradeTiles(1, 1)) }}
						</button>
					</div>
					
					<div class="col-7 coord-container">
						<div :class="`land-coord ${userOwnsTile(tile) ? 'owned': ''}`" v-for="(tile,idx) in requiredUpgradeTiles(1,1)"
							:key="idx">{{ tile.x }},{{ tile.y }}
						</div>
					</div>
					<div class="col-2 text-left">
						<button v-on:click="selectUpgrade(1,1)" class="btn btn-primary btn-sm btn-view">View</button>
						<button v-on:click="triggerUpgrade(1,1)" :disabled="!ownsAllTiles(requiredUpgradeTiles(1,1))" class="btn btn-primary btn-sm btn-view">
							Upgrade
						</button>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-3">
						<button v-on:click="triggerUpgrade(-1, 1)" :disabled="!ownsAllTiles(requiredUpgradeTiles(-1, 1))"
							class="btn btn-primary btn-view btn-sm">North
							West - {{ tileOwnership(requiredUpgradeTiles(-1, 1)) }}
						</button>
					</div>
					
					<div class="col-7 coord-container">
						<div :class="`land-coord ${userOwnsTile(tile) ? 'owned': ''}`" v-for="(tile,idx) in requiredUpgradeTiles(-1, 1)"
							:key="idx">{{ tile.x }},{{ tile.y }}
						</div>
					</div>
					<div class="col-2 text-left">
						<button v-on:click="selectUpgrade(-1, 1)" class="btn btn-primary btn-sm btn-view">View</button>
						<button v-on:click="triggerUpgrade(-1, 1)" :disabled="!ownsAllTiles(requiredUpgradeTiles(-1, 1))"
							class="btn btn-primary btn-sm btn-view">Upgrade
						</button>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-3">
						<button v-on:click="triggerUpgrade(1,-1)" :disabled="!ownsAllTiles(requiredUpgradeTiles(1,-1))" class="btn btn-primary btn-view btn-sm">
							South
							East - {{ tileOwnership(requiredUpgradeTiles(1, -1)) }}
						</button>
					</div>
					
					<div class="col-7 coord-container">
						<div :class="`land-coord ${userOwnsTile(tile) ? 'owned': ''}`" v-for="(tile,idx) in requiredUpgradeTiles(1,-1)"
							:key="idx">{{ tile.x }},{{ tile.y }}
						</div>
					</div>
					<div class="col-2 text-left">
						<button v-on:click="selectUpgrade(1,-1)" class="btn btn-primary btn-sm btn-view">View</button>
						<button v-on:click="triggerUpgrade(1,-1)" :disabled="!ownsAllTiles(requiredUpgradeTiles(1,-1))" class="btn btn-primary btn-sm btn-view">
							Upgrade
						</button>
					</div>
				
				</div>
				<div class="row mt-2">
					<div class="col-3">
						<button v-on:click="triggerUpgrade(-1,-1)" :disabled="!ownsAllTiles(requiredUpgradeTiles(-1,-1))"
							class="btn btn-primary btn-view btn-sm">South
							West - {{ tileOwnership(requiredUpgradeTiles(-1, -1)) }}
						</button>
					</div>
					
					<div class="col-7 coord-container">
						<div :class="`land-coord ${userOwnsTile(tile) ? 'owned': ''}`" v-for="(tile,idx) in requiredUpgradeTiles(-1,-1)" :key="idx">{{
								tile.x
							}},{{ tile.y }}
						</div>
					</div>
					<div class="col-2 text-left">
						<button v-on:click="selectUpgrade(-1,-1)" class="btn btn-primary btn-sm btn-view">View</button>
						<button v-on:click="triggerUpgrade(-1,-1)" :disabled="!ownsAllTiles(requiredUpgradeTiles(-1,-1))"
							class="btn btn-primary btn-sm btn-view">Upgrade
						</button>
					</div>
				</div>
			</div>
			<div class="col-4 text-right p-0">
				<SectorMap :center="{x: topLeft.x, y: topLeft.y}" :tiles="land" :extra-tiles="requiredUpgradeTiles(ux,uy)" :tile-length="1"
					:map-size="8"></SectorMap>
			</div>
		</div>
	
	</div>
</template>

<script>
	import SectorMap from "../land/map/SectorMap";
	
	export default {
		name: "UpgradeContainer",
		components: {SectorMap},
		props: {
			ownedLand: { //Land owned by the user around this building
				type: Array,
				required: true,
			},
			land: { //Land surrounding the building
				type: Array,
				required: true,
			},
			building: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				ux: 1,
				uy: 1,
				upgrade: {
					north: false,
					south: false,
					east: false,
					west: false,
				}
			}
		},
		computed: {
			ownedLandMap: function () {
				const m = {}
				console.log("ol", this.ownedLand);
				for (let i = 0; i < this.ownedLand.length; i++) {
					const l = this.land[i]
					if (l === undefined) {
						console.log("Undefined: ", i, l)
						continue
					}
					console.log("l", l);
					m[`${l.x}|${l.y}`] = l
				}
				
				console.log("OLM: ", m);
				return m
			},
			
			sorted: function () {
				const land = this.building.land;
				return land.sort((a, b) => {
					return a.x - b.x || a.y - b.y;
				});
			},
			
			topLeft: function () {
				return this.sorted[0]
			},
			bottomRight: function () {
				return this.sorted[this.sorted.length - 1]
			},
		},
		methods: {
			selectUpgrade: function (x, y) {
				this.ux = x;
				this.uy = y
			},
			
			userOwnsTile: function (tile) {
				return this.ownedTilesInSelection([{x:tile.x,y:tile.y}]).length > 0
			},
			
			triggerUpgrade: function (x, y) {
				alert(`Unable to upgrade ${x},${y} - Coming soon!`)
			},
			
			requiredUpgradeTiles: function (x, y) {
				let startX = 0;
				let startY = 0;
				
				//East
				if (x === 1) {
					if (y === -1) //South
						startY = 0
					else
						startX = -1
					//West
				} else {
					if (y === -1) {  //South
						startX = 0
						startY = -1
					} else {
						startX = -1
						startY = -1
					}
				}
				
				return this.requiredX(x, startX).concat(this.requiredY(y, startY))
			},
			
			ownedTilesInSelection: function (tiles) {
				// console.log("OTIS: ", tiles);
				return tiles.filter(tile => {
					// console.log("OT: ", tile);
					return this.ownedLand.map(l => `${l.x}|${l.y}`).indexOf(`${tile.x}|${tile.y}`) !== -1
					// return this.ownedLandMap[`${tile.x}|${tile.y}`] !== undefined
				})
			},
			
			ownsAllTiles: function (tiles) {
				// console.log("OAT", this.ownedTilesInSelection(tiles))
				return this.ownedTilesInSelection(tiles).length === tiles.length
			},
			
			tileOwnership: function (tiles) {
				return `${this.ownedTilesInSelection(tiles).length}/${tiles.length}`
			},
			
			requiredY: function (y, start = 0) {
				const tiles = [];
				for (let i = start; i <= this.building.land.length - Math.abs(start); i++) {
					let tile = {
						x: this.topLeft.x + i,
						y: y > 0 ? this.topLeft.y - 1 : this.bottomRight.y + 1
					}
					tiles.push(tile);
				}
				
				return tiles;
			},
			
			requiredX: function (x, start = 0) {
				const tiles = [];
				for (let i = start; i <= this.building.land.length - Math.abs(start); i++) {
					let tile = {
						x: x < 0 ? this.bottomRight.x - 1 : this.topLeft.x + 1,
						y: this.topLeft.y + i
					}
					tiles.push(tile);
				}
				
				return tiles;
			},
		}
	}
</script>

<style scoped>
	.coord-container {
		vertical-align: middle;
		text-align: left;
		margin: auto;
	}
	
	.land-coord:hover {
		-webkit-transform: scale(1.1);
		transform: scale(1.1, 1.1);
	}
	
	.btn-view {
		border: none;
		background: transparent;
		transition: all 60ms ease-out;
	}
	
	.btn-view:hover, .btn-view:active:not(:disabled):not(.disabled), .btn-view:focus, .btn-view::selection {
		background: transparent;
		box-shadow: none;
		transform: scale(1.1);
	}
	
	.building-level {
		text-transform: uppercase;
		letter-spacing: 0.2rem;
		color: #0a9fb9;
		font-weight: bold;
	}
</style>
