<template>
	<div class="card-body">
		<h1 class="mb-5">Land Select</h1>
		
		<form class="form">
			<label>Land To Build On: </label>
			<div class="row" v-if="selected_land.owner_id !== ''">
				<div class="col-8">
					<div class="table-container">
						<table class="table table-sm">
							<thead>
							<tr>
								<th>ID</th>
								<th>X</th>
								<th>Y</th>
								<th></th>
							</tr>
							</thead>
							<tbody>
							<tr class="land" v-for="l in land" :key="`${l.x}${l.y}`">
								<td><code>{{ l.id ? l.id : '2wci94quHBAAVt1HC4T5SUerZR7699LMb8Ueh3CSVpTX' }}</code></td>
								<td>{{ l.x }}</td>
								<td>{{ l.y }}</td>
								<td>
									<button class="btn btn-primary btn-sm btn-super-sm" :disabled="isSelected(l)"
										v-on:click="(e) => {e.preventDefault(); selectLand(l)}">
										{{ isSelected(l) ? "SELECTED" : "SELECT" }}
									</button>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				
				<div class="col-4 text-center mt-5">
					<SectorMap :center="selected_land" :extra-tiles="land" :tiles="[]"
						:tile-length="details.land ? details.land.length : 0"></SectorMap>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-12 text-center">
					<button class="btn btn-primary btn-block" v-on:click="onNext">Next</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import SectorMap from "../land/map/SectorMap";
	
	export default {
		name: "BuildingLandStep",
		components: {SectorMap},
		props: {
			land: {
				type: Array,
				required: true
			},
			form: {
				type: Object,
				required: true,
			},
			editMode: {
				type: Boolean,
				required: true
			},
		},
		data() {
			return {
				selected_land: {
					x: 0,
					y: 0,
				},
				details: {
					spawn_chunk_x: 0,
					spawn_chunk_y: 0,
				}
			}
		},
		methods: {
			isValid: function () {
				return (this.editMode || this.selected_land.id !== -1)
			},
			onNext: function (e) {
				e.preventDefault();
				
				if (!this.isValid()) {
					alert("Invalid form")
					return
				}
				
				this.$emit('next', {
					spawn_chunk_x: this.selected_land.x,
					spawn_chunk_y: this.selected_land.y,
				})
			},
			isSelected: function (l) {
				return this.selected_land === l;
			},
			
			selectLand: function (l) {
				this.selected_land = l
			}
		},
		beforeMount() {
			this.details = {
				spawn_chunk_x: this.form.spawn_chunk_x,
				spawn_chunk_y: this.form.spawn_chunk_y,
			}
			this.selected_land = {
				x: this.form.spawn_chunk_x,
				y: this.form.spawn_chunk_y,
			}
		}
	}
</script>

<style scoped>
	h2 {
		color: white;
		font-size: 1.2rem;
	}
	
	code {
		color: white;
		font-size: 0.8rem;
	}
	
	tr.land {
		vertical-align: middle;
		font-size: 0.8rem;
		line-height: 30px;
	}
	
	.btn-super-sm {
		height: auto;
	}
	
	.table-container {
		overflow-y: scroll;
		max-height: 500px;
	}
</style>