<template>
	<div class="wrapper" v-on:click="$emit('hide')" :style="{right: show ? 0: '-100vw'}">
		<div class="sidenav col-2 px-1 pt-3" v-on:click="preventHide">
			<h4 class="text-center mb-0 section-common-title section-common-title-center">Basket</h4>
			
			
			<h3 class="mt-3">Tiles </h3>
			<div class="item-container text-center">
				<div class="item" v-for="(item, idx) in shopping.basket" :key="idx">{{ item }}</div>
			</div>
			
			<div class="nav-footer">
				<button class="btn btn-primary" id="purchase-button">PURCHASE Land</button>
			</div>
		</div>
	</div>

</template>

<script>
	// import NavItem from "./NavItem";
	
	export default {
		name: "Landnav",
		props: {
			show: {
				type: Boolean,
				default: false
			},
			shopping: {
				type: Object,
				default: function () {
					return {
						basket: [],
					}
				}
			}
		},
		methods: {
			preventHide: function (e) {
				e.stopPropagation()
			}
		}
	}
</script>

<style scoped>
	.wrapper {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		transition: all 0.5s ease-in-out;
	}
	
	.sidenav {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0.5vw;
		background: #0E0E0F;
		/*background: linear-gradient(90deg, rgba(14, 64, 76, 1) 0%, rgba(22, 99, 117, 1) 100%);*/
		margin-top: 10vh;
		height: 80vh;
		max-height: 80vh;
		border-radius: 14px;
		border: 2px solid #0a9fb9;
	}
	
	h1, h2, h3, h4, h5 {
		color: white;
	}
	
	h4 {
		color: black;
		font-size: 2rem;
	}
	
	.item-container {
		text-align: center;
		position: relative;
		overflow-y: scroll;
		height: 63vh;
	}
	
	.item {
		margin-top: 1vh;
		margin-left: 5%;
		padding: 15px;
		background: rgba(14, 64, 76, 0.8);
		border-radius: 14px;
		border: 2px solid #0a9fb9;
		color: white;
		width: 90%;
		transition: all 0.2s ease-in-out;
	}
	
	.item:hover {
		transform: scale(1.1);
	}
	
	.nav-footer {
		text-align: center;
		width: 50%;
	}
	
	#purchase-button {
		text-align: center;
		position: absolute;
		bottom: 2vh;
	}
</style>