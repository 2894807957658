<template>
	<div class="container-card" v-on:click="selectModel">
		<model-viewer :id="model.id" reveal="manual" class="model-view-preview" alt="Item image" ar ar-modes="webxr scene-viewer quick-look"
			:src="`https://app.babilu.online${model.model_src}`" seamless-poster :poster="`https://app.babilu.online${model.image_src}`"></model-viewer>
		<h2>{{ model.name }}</h2>
	</div>
</template>

<script>
	import "@google/model-viewer"
	
	export default {
		name: "BuildingModelCard",
		props: {
			model: Object
		},
		methods: {
			selectModel: function () {
				this.$emit("select-model", this.model);
			},
		}
	}
</script>

<style scoped>
	.container-card {
		position: relative;
	}
	
	h2 {
		color: white;
		font-size: 1.2rem;
		text-align: center;
		margin-bottom: 0;
	}
	
	.model-view-preview {
		--poster-color: transparent;
		height: 200px;
		width: 100%;
	}
</style>