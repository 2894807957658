<template>
    <div class="index">
        <BannerTop title_blue="Oasis" title_white="Explorer"
                   info="Search for rooms to join or for items to view"></BannerTop>

        <div class="container">
            <div class="row mt-3">
                <div class="col-12 row">
                    <div class="col-md-3 col-sm-11 offset-md-8">
                        <input class="form-control form-control-lg" placeholder="Search via Name,Address or ID">
                    </div>
                    <div class="col-1">
                        <button class="btn btn-primary">Search</button>
                    </div>
                </div>
            </div>

            <div class="room-table mt-3">
                <table class="table table-striped">
                    <thead>
                    <th :key="h.name" v-for="(h) in headers" :colspan="h.span" :class="h.class">{{h.name}}</th>
                    </thead>
                    <tbody>
                    <tr :key="r" v-for="(row,r) in data">
                        <!--                        <td><a :href="`https://opensea.io/assets/${row.saga}/${row.id}`">{{row.id.substring(row.id.length - 16)}}</a></td>-->
                        <td><img :src="row.preview"></td>
                        <td>{{row.name}}</td>
                        <td>
                            <router-link :to="`/players/${row.owner_id}`">{{owner(row)}}</router-link>
                        </td>
                        <td>{{row.players_in_room}}/{{row.capacity}}</td>
                        <td class="text-center">
							<router-link class="btn btn-primary btn-sm btn-link" :to="`/oasis/${row.token_id}/visit`">Join</router-link>
							<router-link class="btn btn-secondary btn-sm btn-link" :to="`/oasis/${row.token_id}`">Info</router-link>
						</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
	import Room from "@/api/room"
	import BannerTop from "../../components/BannerTop";
	
	export default {
		name: "RoomIndex",
		components: {BannerTop},
		data() {
			return {
				headers: [
					// {name: "ID", span: 1, class: ""},
					{name: "Preview", span: 1, class: ""},
					{name: "Room Name", span: 1, class: ""},
					{name: "Owner", span: 1, class: ""},
					{name: "User Count", span: 1, class: ""},
                    {name: "Actions", span: 2, class: "text-center"}
                ],
                data: [],
            }
        },
        methods: {
            getTableData: function () {
				Room.index().then((r) => this.data = r.data);
            },
            owner: function(room) {
                if (room.owner_name !== "" && room.owner_name !== undefined)
                    return room.owner_name;

                return room.owner_id;
            }
        },
        mounted() {
            this.getTableData();
        }
    }
</script>

<style scoped>
	table,
	.btn-link {
		color: white;
	}
</style>