<template>
	<div id="sector-tooltip">
		<div class="row">
			<div class="col-9 title">
				<h6>{{ activeSector.name }} Sector</h6>
			</div>
			<div class="col-3 ranking">
				<i class="fa fa-globe"></i> {{ activeSector.rank }}<sup>{{ numberToSuper(activeSector.rank) }}</sup>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<p>{{ activeSector.description }}</p>
			</div>
		</div>
		<div class="row info-row text-center">
			<div class="col-4"><i class="fa fa-location-dot"></i> {{ activeSector.tiles_available }}/7500</div>
			<div class="col-4 "><i class="fa fa-user"></i> {{
					activeSector.users.online
				}}/{{ activeSector.users.capacity }}
			</div>
			<div class="col-4"><i class="fa fa-dollar"></i> {{ activeSector.floor_price }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SectorTooltip",
	props: {
		activeSector: {
			type: Object,
			required: true
		},
		mouse: {
			//
		}
	},
	watch: {
		mouse: function () {
			document.getElementById("sector-tooltip").style.top = (this.mouse.clientY) + "px";
			document.getElementById("sector-tooltip").style.left = (this.mouse.clientX) + "px";
		}
	},
	methods: {
		//return the superscript of a given number
		numberToSuper(num) {

			if (num >= 20)
				num = num % 10;

			if (num === 0)
				return "th";
			if (num === 1)
				return "st"
			if (num === 2)
				return "nd"
			if (num === 3)
				return "rd"
			if (num >= 4)
				return "th"
		},
	}
}
</script>

<style scoped>

h6 {
	text-align: left;
	font-weight: bold;
}

.ranking {
	text-align: right;
}

.info-row {
	font-size: 0.7rem;
}

#sector-tooltip {
	user-select:none;
	position: absolute;
	z-index: 9;
	min-width: 50px;
	/*height: 50px;*/
	background: rgba(255, 255, 255, 0.7);
	color: black;
	border-radius: 9px;
	padding: 5px;
	text-align: left;
	font-size: 0.8rem;

	margin-top: -100px;
	margin-left: -100px;
}

p {
	font-size: 0.7rem;
	margin-bottom: 0;
}
</style>