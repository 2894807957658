<template>
	<div class="container-fluid p-4">
		<div class="row mt-3">
			<div class="col-6">
				<div class="card wow fadeIn">
					<div class="card-body">
						<div class="row mb-3">
							<div class="col-8">
								<h1 class="mb-3">Summary<small>
									<router-link :to="`/buildings/${building.token_addr}/edit`" class="btn-none btn-sm">EDIT</router-link>
								</small></h1>
							</div>
							<div class="col-4 text-right">
								<img v-if="this.building.logo" :src="this.building.logo.public_uri" class="building-logo">
							</div>
						</div>
						
						<div class="row mt-3">
							<div class="col-3">
								<div class="analytic-card">
									<h1 class="">{{ 8 * building.land.length }}x{{ 8 * building.land.length }}</h1>
									<h2>Max Size</h2>
								</div>
							</div>
							<div class="col-3">
								<div class="analytic-card">
									<h1 class="">{{ (building.land.length * 10) - rooms.length }}/{{ building.land.length * 10 }}</h1>
									<h2>Capacity</h2>
								</div>
							</div>
							<div class="col-3">
								<div class="analytic-card">
									<h1 class="">0 <small>ABMT</small></h1>
									<h2>Monthly Rent</h2>
								</div>
							</div>
							<div class="col-3">
								<div class="analytic-card">
									<h1 class="">1 <small>Month</small></h1>
									<h2>Tenancy Length</h2>
								</div>
							</div>
						</div>
						
						
						<div class="row mt-5">
							<table class="table">
								<tbody>
								<tr>
									<td>Name</td>
									<td>{{ building.name }}</td>
								</tr>
								<tr>
									<td>Model:</td>
									<td>{{ models[building.building_model_id] ? models[building.building_model_id].name : '-' }}</td>
								</tr>
								<tr>
									<td>Token Address:</td>
									<td>{{ building.token_addr }}</td>
								</tr>
								<tr>
									<td>Owner(s):</td>
									<td>
										<router-link :to="`/players/${l.owner_addr}`" class="owner" v-for="l in building.land" :key="l.id">{{ l.owner_addr }}
										</router-link>
									</td>
								</tr>
								<tr>
									<td>Target Oasis:</td>
									<td>{{ oasis.name }}</td>
								</tr>
								<tr>
									<td>City Position:</td>
									<td>{{ building.spawn_chunk_x }},{{ building.spawn_chunk_y }} + {{ building.position_x }},{{ building.position_z }}</td>
								</tr>
								<tr>
									<td>Land Staked:</td>
									<td>{{ building.land.length }}</td>
								</tr>
								<tr>
									<td>Rotation:</td>
									<td>{{ building.position_r }} Degrees</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-6" v-if="models[building.building_model_id]">
				<div class="col-12">
					<model-viewer seamless-poster reveal="auto" auto-rotate camera-controls class="model-view" alt="Item image" ar
						ar-modes="webxr scene-viewer quick-look"
						:src="`https://app.babilu.online${models[building.building_model_id].model_src}`"
						:poster="`https://app.babilu.online${models[building.building_model_id].image_src}`"></model-viewer>
				</div>
				<div class="col-12">
					<div class="card mt-3">
						<h2>Analytics</h2>
						
						<div class="row mt-3">
							<div class="col-3">
								<div class="analytic-card">
									<h1 class="">0</h1>
									<h2>Unique Visits</h2>
								</div>
							</div>
							<div class="col-3">
								<div class="analytic-card">
									<h1 class="">0 <small>s</small></h1>
									<h2>Average Dwell Time</h2>
								</div>
							</div>
							<div class="col-3">
								<div class="analytic-card">
									<h1 class="">0</h1>
									<h2>Adverts Served</h2>
								</div>
							</div>
							<div class="col-3">
								<div class="analytic-card">
									<h1 class="">0 <small>ABMT</small></h1>
									<h2>Revenue Generated</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="card wow fadeIn mt-3">
					<UpgradeContainer v-if="ownedLand.length >0" class="mt-3" :building="building" :owned-land="ownedLand"
						:land="surroundingLand"></UpgradeContainer>
				</div>
			</div>
		</div>
		
		<div class="row mt-3">
			<div class="col-12">
				<div class="card">
					<h2 class="mb-3">Hosted Oasis</h2>
					
					<table class="table table-borderless table-sm">
						<thead>
						<tr>
							<th :key="h.name" v-for="(h) in headers" :colspan="h.span" :class="h.class">{{ h.name }}</th>
						</tr>
						</thead>
						<tbody>
						<tr :key="r" v-for="(row,r) in rooms">
							<td>{{ row.token_id }}</td>
							<td>{{ toCategory(row.category) }}</td>
							<td>{{ row.name }}</td>
							<td>{{ row.owner_id }}</td>
							<td class="text-center">{{ row.tile_x }}x{{ row.tile_y }}</td>
							<td>{{ row.players_in_room }} / {{ row.capacity }}</td>
							<td class="text-center">0</td>
							<td class="text-center">0 <small>ABMT</small></td>
							<!--								<td>{{row.token_id}}</td>-->
							<!--								<td>{{row.name}}</td>-->
						</tr>
						</tbody>
					</table>
					
					<div class="col-12 text-center">
						<router-link :to="`/buildings/${building.token_addr}/oasis/create`" class="btn btn-primary btn-table btn-sm mt-3">Create Oasis
						</router-link>
					</div>
				</div>
			</div>
		</div>
		
		<div class="row mt-3">
			<div class="col-12">
				<div class="card">
					<h2 class="mb-3">Staked Land</h2>
					
					<table class="table table-borderless table-sm">
						<thead>
						<tr>
							<th :key="h.name" v-for="(h) in landHeaders" :colspan="h.span" :class="h.class">{{ h.name }}</th>
						</tr>
						</thead>
						<tbody>
						<tr :key="r" v-for="(row,r) in building.land">
							<td>
								<router-link :to="`/land/${row.x}/${row.y}`"><code>{{ row.token_addr }}</code></router-link>
							</td>
							<td>
								<router-link :to="`/players/${row.owner_addr}`"><code>{{ row.owner_addr }}</code></router-link>
							</td>
							<td>{{ row.x }}</td>
							<td>{{ row.y }}</td>
							<td>{{ row.purchased_at }}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Building from "@/api/building"
	import Room from "@/api/room"
	import Land from "@/api/land"
	import "@google/model-viewer"
	import UpgradeContainer from "../../components/building/UpgradeContainer";
	
	export default {
		name: "BuildingShow",
		components: {UpgradeContainer},
		data() {
			return {
				models: {},
				oasis: {
					name: "-",
				},
				surroundingLand: [],
				ownedLand: [],
				building: {
					spawn_chunk_x: 0,
					spawn_chunk_y: 0,
					land: [],
				},
				logo: {
					public_uri: ""
				},
				rooms: [],
				headers: [
					{name: "ID", span: 1, class: ""},
					{name: "Category", span: 1, class: ""},
					{name: "Name", span: 1, class: ""},
					{name: "Owner", span: 1, class: ""},
					{name: "Dimensions", span: 1, class: ""},
					{name: "Capacity", span: 1, class: ""},
					{name: "Unique visits", span: 1, class: "text-center"},
					{name: "Net worth", span: 1, class: "text-center"}
				],
				landHeaders: [
					{name: "Token Addr", span: 1, class: ""},
					{name: "Owner", span: 1, class: ""},
					{name: "X", span: 1, class: ""},
					{name: "Y", span: 1, class: ""},
					{name: "Last Purchased", span: 1, class: ""},
				],
			}
		},
		methods: {
			
			getBuilding: function () {
				Building.show(this.$route.params.id).then(resp => {
					this.building = resp.data;
					this.getTargetOasis()
					this.getSurroundingLand()
				})
			},
			getBuildingTenants: function () {
				Building.indexRooms(this.$route.params.id).then(resp => this.rooms = resp.data)
			},
			
			getTargetOasis: function () {
				Room.show(this.building.target_oasis_id).then(resp => {
					this.oasis = resp.data;
				})
			},
			
			getSurroundingLand: function () {
				const sx = this.building.spawn_chunk_x
				const sy = this.building.spawn_chunk_y
				console.log(`Loading tiles ${sx},${sy}`);
				Land.indexRadius(sx, sy, 5).then(resp => {
					this.surroundingLand = resp.data;
					
					const wallet = this.$store.state.wallet_addr
					this.ownedLand = resp.data.filter(l => {
						return l.owner_addr === wallet
					});
				})
			},
			
			getModels: function () {
				Building.indexModels().then(resp => {
					for (const m in resp.data) {
						this.models[resp.data[m].id] = resp.data[m]
					}
					console.log("models:", this.models)
				})
			},
			
			toCategory: function (cat) {
				switch (cat) {
					default:
					case 0:
						return "-"
					case 1:
						return "Personal"
					case 2:
						return "Casino"
					case 3:
						return "Sponsor"
					case 4:
						return "Community"
					case 5:
						return "Founder"
					case 6:
						return "Public"
				}
			}
		},
		
		mounted: function () {
			this.getModels();
			this.getBuilding()
			this.getBuildingTenants()
		}
	}
</script>

<style scoped>
	.model-view {
		--poster-color: transparent;
		height: 560px;
		width: 100%;
	}
	
	.analytic-card {
		text-align: center;
	}
	
	h2 {
		color: white;
		font-size: 1.2rem;
	}
	
	small {
		font-size: 0.8rem;
		color: white;
	}
	
	.analytic-card h1 {
	
	}
	
	.analytic-card h2 {
		color: white;
		font-size: 1rem;
	}
	
	.owner {
		font-size: 0.6rem;
		border: 2px solid #0a9fb9;
		border-radius: 8px;
		padding: 5px;
		background: transparent;
		transition: all 0.3s ease-out;
	}
	
	.owner:hover {
		background: #0a9fb9;
		cursor: pointer;
		color: white;
	}
</style>