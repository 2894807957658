<template>
	<div class="index col-12">
		<div class="row mb-3 toolbar">
			<div class="col-4 col-md-4 col-sm-2">

				<button class="btn btn-sm btn-primary" v-on:click="$emit('back')">Back to Map</button>
			</div>
			<div class="col-4 col-md-4 col-sm-8 text-center">
				<h2>{{ activeSector.name }} Sector</h2>
			</div>
			<div class="col-4 col-md-4 col-sm-2 text-right">
				<button class="btn btn-primary btn-sm" v-on:click="toggleBasket">Basket <small
						v-show="Object.keys(this.shopping.basket).length > 0">({{
						Object.keys(this.shopping.basket).length
					}})</small></button>
			</div>
		</div>
		<div class="row">
			<div class="col-12 text-center">
				<div id="canvas-container" class="land-map" @wheel="handleWheel">
					<LoadingSmall v-if="!allSectorsLoaded" :progress="sector.loaded" :total="this.tilesToLoad"></LoadingSmall>
					<div id="zoom-container text-center" :style="{transform: `scale(${this.scale})`}">
						<canvas v-on:click="mapClick" id="canvas" :width="this.mapSize*this.tileSize"
										:height=this.mapSize*this.tileSize></canvas>
					</div>
					<Tooltip @navigate="onNavigate" @add-basket="onAddBasket" :tile="tooltip.tile" :show="tooltip.show" :chunk="tooltip.chunk" :position="tooltip.position" :selected="selectedTiles"></Tooltip>
				</div>
			</div>
		</div>
		<Landnav @hide="shopping.basket_open = false" :shopping="shopping" :show="shopping.basket_open"></Landnav>
	</div>
</template>

<script>
import {gsap} from 'gsap'
import Land from "@/api/land"
import Draggable from 'gsap/Draggable';
import Tooltip from "../../components/land/map/Tooltip";
import Landnav from "./Landnav";
import LoadingSmall from "../../components/LoadingSmall";

export default {
	name: "LandIndexSector",
	components: {LoadingSmall, Landnav, Tooltip},
	props:{
		activeSector: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			tilesToLoad: 8,
			sector: {
				loaded: 0,
				primed: 0,
				x: -200,
				y: -200,
				size: 50,
				loading_cache: {},
				loading_start: 0,
			},
			scale: 1,
			lastSelectScale: 1,
			mapSize: 86,
			tileSize: 10,
			tiles: {},
			shopping: {
				basket_open: false,
				basket: {},
			},
			sectorColors: {},
			map: [],
			selectedTiles: [],
			workerIdx: 0,
			workerPool: [],
			ctx: null,
			canvas: null,
			loading: null,
			tooltip: {
				show: false,
				chunk: [0, 0],
				position: {
					x: 0,
					y: 0,
				},
				tile: {
					name: "",
					guild: {name: ""},
				},
			}
		}
	},
	computed: {
		allSectorsLoaded: function () {
			return this.sector.loaded >= this.tilesToLoad
		}
	},
	methods: {
		toCenter: function () {
			return
			// const cc = document.getElementById("canvas-container"); //Physical size of container
			//
			// let centerY = (this.mapSize * this.tileSize) - ((this.tileSize * this.mapSize) / this.scale);
			// const center = {
			// 	x: -((this.mapSize * this.tileSize)) - (-(cc.clientWidth / 2)),
			// 	y: -((this.mapSize * this.tileSize)) - (-(cc.clientHeight / 2 / this.scale)) + centerY
			// }
			//
			// gsap.to("#canvas", 1, center);
		},

		mapClick: function (e) {
			console.log("Click", e);
			this.lastSelectScale = this.scale;

			var rect = e.target.getBoundingClientRect();
			var x = (e.clientX - rect.left) / this.scale; //x position within the element.
			var y = (e.clientY - rect.top) / this.scale;  //y position within the element.

			let tx = Math.floor(x / this.tileSize);
			let ty = Math.floor(y / this.tileSize);

			// console.log("Left? : " + x + " ; Top? : " + y + ".");
			console.log(`Tile: ${tx},${ty}`);

			if (e.shiftKey) {
				if (this.selectedTiles.length === 1) {
					const ft = this.selectedTiles[0];
					if (tx > ft[0])
						tx += 1
					if (ty > ft[1])
						ty += 1

					this.selectedTiles.push([tx, ty]);
					this.shiftClick(ft, [tx, ty]);
					this.showTooltipAt((e.clientX - rect.left) + 20, (e.clientY - rect.top))
					return;
				}
			}

			this.tooltip.chunk = this.toChunk(x / this.tileSize, y / this.tileSize);
			this.showTooltipAt(e.clientX + 20, e.clientY)

			console.log("Clicked", `${this.tooltip.chunk[0]}|${this.tooltip.chunk[1]}`);

			this.clearSelectedTiles();
			this.selectedTiles = [[tx, ty]] //Reset selected tiles as not shift clicking

			this.drawSelect(tx * this.tileSize, ty * this.tileSize, 1);
			this.ctx.stroke();
		},

		shiftClick: function (e1, e2) {
			console.log("Shift click");
			this.tooltip.show = false;

			//Clear the double draw
			this.ctx.fillStyle = this.color(e1[0], e1[1]);
			this.ctx.clearRect(Math.floor((e1[0] * this.tileSize) / this.scale), Math.floor((e1[1] * this.tileSize) / this.scale), this.tileSize, this.tileSize)
			this.draw(e1[0] * this.tileSize, e1[1] * this.tileSize, 1);

			for (let x = Math.min(e1[0], e2[0]); x < Math.max(e1[0], e2[0]); x++) {
				for (let y = Math.min(e1[1], e2[1]); y < Math.max(e1[1], e2[1]); y++) {
					this.selectedTiles.push([x, y]);
					this.drawSelect(x * this.tileSize, y * this.tileSize, 1);
				}
			}
			this.ctx.stroke();
		},

		showTooltipAt: function (x, y) {
			this.tooltip.position = {x: x, y: y};
			this.tooltip.tile = this.tileDetail(this.tooltip.chunk[0], this.tooltip.chunk[1]);
			console.log("tile", this.tooltip.tile)
			this.tooltip.show = true;
		},

		clearSelectedTiles: function () {
			for (let i = 0; i < this.selectedTiles.length; i++) {
				const t = this.selectedTiles[i];

				this.ctx.clearRect((t[0] * this.tileSize), (t[1] * this.tileSize), this.tileSize, this.tileSize);
				// this.ctx.clearRect((t[0] * this.tileSize), (t[1] * this.tileSize), this.tileSize, this.tileSize);
				console.log("Clearing tile: ", t);
				this.ctx.fillStyle = this.color(t[0], t[1]);
				this.draw((t[0] * this.tileSize) * this.scale, (t[1] * this.tileSize) * this.scale, 1);
			}
		},

		color: function (x, y) {
			//0|0

			const tx = x - this.mapSize
			const ty = y - this.mapSize

			if (this.shopping.basket[`${Math.floor(tx)}|${Math.floor(ty)}`]) {
				console.log("Basket override");
				return "#9932CC"
			}

			const foundTile = this.tiles[`${Math.floor(tx)}|${Math.floor(ty)}`]
			if (!foundTile) {
				return "rgb(31,31,30)"
			}

			if (foundTile.Buildings.length > 0) {
				return "#26B6D4"
			}

			if (foundTile.owner_addr !== "") {
				// return "rgb(0,0,0)"
				return "rgb(255,0,0)"
			}

			return "rgb(0,255,0)"
		},

		/**
		 * Build map (not doubled)
		 */
		buildMap: function () {
			console.log("Map Size: ", this.mapSize);
			for (let x = 0; x <= this.mapSize; x++) {
				this.map[x] = [];
				for (let y = 0; y <= this.mapSize; y++)
					this.map[x][y] = `${x - this.mapSize}${y - this.mapSize}`
			}
		},

		drawMap: function () {
			this.ctx.fillStyle = "blue";
			this.ctx.strokeStyle = "black";
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.ctx.stroke();
			for (let x = 0; x < this.map.length; x++) {
				for (let y = 0; y < this.map[x].length; y++) {
					this.ctx.fillStyle = this.color(x, y);
					this.draw(x * this.tileSize, y * this.tileSize, 1);
				}
			}
			this.ctx.stroke();
		},


		drawSector: function (xs, ys, size) {
			// console.log("Drawing sector: ", xs, ys, size)
			this.ctx.clearRect(xs, ys, this.tileSize, this.tileSize)
			for (let x = 0; x < size; x++) {
				for (let y = 0; y < size; y++) {
					this.ctx.fillStyle = this.color(xs + x, ys + y);
					this.draw((xs + x) * this.tileSize, (ys + y) * this.tileSize, 1);
				}
			}
			this.ctx.stroke();
		},

		onNavigate: function (uri) {
			this.$emit("navigate", uri)
		},

		onAddBasket: function (tilePosition) {
			const key = `${tilePosition[0]}|${tilePosition[1]}`;

			console.log("adding tile to basket:", tilePosition, key);

			this.shopping.basket[key] = tilePosition
			if (!this.shopping.basket_open)
				this.toggleBasket();
		},

		toggleBasket: function () {
			if (!this.shopping.basket_open)
				this.tooltip.show = false;

			this.shopping.basket_open = !this.shopping.basket_open;
		},

		/**
		 * TODO Return actual tile details
		 * @param tx
		 * @param ty
		 * @returns {{owner: string, image: string, guild: {name: string}, name: string, points: number}}
		 */
		tileDetail: function (tx, ty) {
			if (this.tiles[`${tx}|${ty}`]) {
				const t = this.tiles[`${tx}|${ty}`];
				return {
					logo_id: t.Buildings && t.Buildings.length > 0 ? t.Buildings[0].logo_id : -1,
					image: "/lounge/virtual_room_logo.png",
					name: t.Buildings && t.Buildings.length > 0 ? t.Buildings[0].name : t.owner_addr === '' ? "Empty Land" : "Owned Land",
					owner: t.owner_addr,
					id: t.token_addr,
					building_id: t.Buildings && t.Buildings.length > 0 ? t.Buildings[0].token_addr : '',
					guild: {"name": "AlphaBatem"},
					position: {x: tx, y: ty},
					points: 1698,
				}
			}

			console.log("Cant find tile detail: ", `${tx}|${ty}`);
			return {
				image: "/lounge/virtual_room_logo.png",
				name: "Reserved Land",
				owner: "",
				guild: {"name": "-"},
				position: {x: tx, y: ty},
				points: 1698,
			}
		},

		toChunk(x, y) {
			console.log(`ToChunk: ${Math.floor(x) / this.tileSize},${Math.floor(y) / this.tileSize} - ${this.mapSize}`);
			const b = this.mapSize; //Middle out
			return [Math.floor(x - b), Math.floor(y - b)]
		},

		draw(x, y, size) {
			const border = (this.tileSize / 100) * 3;
			this.ctx.fillRect(Math.floor(x / this.scale) + border, Math.floor(y / this.scale) + border, this.tileSize * size - (border * 2), this.tileSize * size - (border * 2));
		},

		drawSelect(x, y, size) {
			this.ctx.fillStyle = "RGBA(55,60,164,0.81)";
			this.ctx.fillRect(x, y, this.tileSize * size, this.tileSize * size);
		},

		handleWheel: function (e) {
			e.preventDefault();
			// return

			if (!this.allSectorsLoaded)
				return //Dont zoom while building map

			// const currentTileSize = this.scale;

			if (e.deltaY < 0) {
				// this.tileSize += Math.abs(e.deltaY) / 100
				this.scale += Math.abs(e.deltaY) / 1000
			} else {
				// this.tileSize -= Math.abs(e.deltaY) / 100
				this.scale -= Math.abs(e.deltaY) / 1000
			}
			if (this.scale <= 0.2)
				this.scale = 0.2;

			if (this.scale > 3)
				this.scale = 3;

			console.log(`delta: ${e.deltaY} = ${this.scale}`, Math.abs(e.deltaY) / 1000)

			this.toCenter()
		},

		loadSector: function () {
			this.loadTiles();
			if (this.sector.x >= this.mapSize) {
				this.sector.x = -200;
				this.sector.y += this.sector.size;
			} else {
				this.sector.x += this.sector.size
			}
		},

		getSectorKey: function (x, y) {
			return `${x}|${y}:${this.sector.size}`
		},

		loadTiles: function () {
			const sx = this.sector.x
			const sy = this.sector.y
			const sectorKey = this.getSectorKey(sx, sy)

			if (this.sector.loading_cache[sectorKey]) {
				console.log(`Skipping (already loaded) tiles ${sx},${sy} - ${sectorKey}`);
				return
			}

			console.log(`Loading tiles ${sx},${sy} - ${sectorKey}`);
			this.sector.loading_cache[sectorKey] = true

			// console.log("Cache miss: ", sectorKey);
			Land.indexSector(sx, sy, this.sector.size).then(resp => {
				this.handleIndexSector(sx, sy, resp.data)
			})
		},

		handleIndexSector: function (sx, sy, data) {
			console.log("Adding land: ", data.length);

			// let cacheData = {};
			const worker = this.workerPool[this.workerIdx]
			worker.postMessage({
				coords: {x: sx, y: sy},
				sectorKey: this.getSectorKey(sx, sy),
				data: data,
			})
			this.workerIdx++;
			if (this.workerIdx >= this.workerPool.length)
				this.workerIdx = 0;
		},

		cacheSector: function (sectorID, tileMap) {
			localStorage.setItem(`map-sector-${sectorID}`, JSON.stringify({
				tiles: tileMap,
				timestamp: Date.now()
			}))
		},

		getCachedSector: function (sectorID) {
			const key = `map-sector-${sectorID}`;
			const val = localStorage.getItem(`map-sector-${sectorID}`);
			if (val === null) {
				return null
			}

			const data = JSON.parse(val)
			const timeout = 1000 * 60 * 60 * 1 //1 hour

			if (Date.now() - timeout > data.timestamp) {
				localStorage.removeItem(key)
				return null //Force refetch
			}

			return data.tiles
		},
	},
	mounted() {
		this.buildMap();

		if (window.Worker) {
			for (let i = 0; i <= navigator.hardwareConcurrency; i++) {
				console.log(`Spawning Worker: ${i}`);
				const worker = new Worker("/workers/map_worker.js")
				worker.onmessage = (e) => {
					// console.log("MSG: ", e.data);

					this.tiles = {...this.tiles, ...e.data.data}

					this.sector.loaded++
					console.log(`${i} - Tiles Loaded:  ${this.sector.loaded}/${this.tilesToLoad}`);
					if (this.allSectorsLoaded) {
						console.log("Sectors loaded, drawing map - Took: ", Date.now() - this.sector.loading_start);
						requestAnimationFrame(() => {
							this.drawMap();
							this.toCenter();

						})
					} else {
						this.drawSector(e.data.coords.x, e.data.coords.y, this.sector.size);
					}
				}

				this.workerPool.push(worker)
			}
		}


		//Load priority sectors
		this.sector.loading_start = Date.now()
		let start = -50;
		for (let i = 0; i < 2; i++)
			for (let j = 0; j < 2; j++) {
				this.sector.x = start + (i * 50);
				this.sector.y = start + (j * 50);
				this.loadTiles()
			}

		//Reset for sector load
		this.sector.x = -200
		this.sector.y = -200

		//Load all sectors
		for (let i = 0; i <= this.mapSize; i += this.sector.size) {
			for (let j = 0; j <= this.mapSize; j += this.sector.size) {
				// console.log(`Sector: ${i} ${j}`)
				this.loadSector()
			}
		}

		// this.loadTiles();
		// this.drawMap();
		gsap.registerPlugin(Draggable);
		Draggable.create("#canvas", {
			bounds: {minX: -(this.mapSize * this.tileSize * 5), minY: -(this.mapSize * this.tileSize * 5), maxX: 0, maxY: 0},
			cursor: "pointer",
			onDragStart: () => {
				this.tooltip.show = false;
			}
		});

		this.canvas = document.getElementById("canvas");
		this.ctx = this.canvas.getContext("2d");
		this.ctx.fillStyle = "blue";
		this.ctx.strokeStyle = "black";
		//
		this.toCenter();
	},
	beforeRouteLeave() {
		console.log("Leaving")
		for (let i in this.workerPool) {
			this.workerPool[i].terminate();
		}
		return true
	},
	beforeDestroy() {
		for (let i in this.workerPool) {
			this.workerPool[i].terminate();
		}
		return true;
	}
}
</script>

<style scoped>
.land-map {
	overflow: hidden;
}

h2 {
	font-size: 1.6rem;
}

.toolbar {
	z-index: 99;
	position: absolute;
	top: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.6);
	padding-top: 15px;
	padding-bottom: 15px;
}

.land-map {
	margin-top: 60px;
}
</style>