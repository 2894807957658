<template>
	<div class="container-fluid mt-2 p-4">
		<div class="row text-center mb-4">
			<div v-if="!editMode" :class="`offset-lg-1 step-info wow fadeInDown ${stepClass(0)}`" data-animation-delay="0.3s" v-on:click="current_step = 0"><span
				class="circle">0</span> <span v-if="current_step === 0" class="text wow fadeIn">LAND SELECTION</span></div>
			<div :class="`step-info wow fadeInDown ${stepClass(1)}`" data-animation-delay="0.3s" v-on:click="current_step = 1"><span
				class="circle">1</span> <span v-if="current_step === 1" class="text wow fadeIn">LAND PLACEMENT</span></div>
			<div :class="`step-info wow fadeInDown ${stepClass(2)}`" data-animation-delay="0.6s" v-on:click="current_step = 2"><span
				class="circle">2</span> <span v-if="current_step === 2" class="text wow fadeIn">SELECT BUILDING</span></div>
			<div :class="`step-info wow fadeInDown ${stepClass(3)}`" data-animation-delay="0.9s" v-on:click="current_step = 3"><span
				class="circle">3</span> <span v-if="current_step === 3" class="text wow fadeIn">BUILDING DETAILS</span></div>
			<div :class="`step-info wow fadeInDown ${stepClass(4)}`" data-animation-delay="1.2s" v-on:click="current_step = 4"><span
				class="circle">4</span> <span v-if="current_step === 4" class="text wow fadeIn">SUMMARY</span></div>
		</div>
		<div class="row" v-if="current_step <= 1">
			<div class="col-8 offset-2 mt-5">
				<div class="card">
					<BuildingInformationStep :edit-mode="editMode" @next="onNext" v-if="current_step === -1"></BuildingInformationStep>
					<BuildingLandStep :edit-mode="editMode" @next="onNext" v-if="current_step === 0" :form="form" :land="land"></BuildingLandStep>
					<BuildingPlacementStep :edit-mode="editMode" @next="onNext" v-if="current_step === 1" :form="form" :land="land"></BuildingPlacementStep>
				</div>
			</div>
		</div>
		<div class="row mt-5" v-if="current_step > 1">
			<div class="col-md-6 col-sm-12 order-sm-2 order-md-1">
				<div class="card wow fadeIn">
					<BuildingSelectStep :edit-mode="editMode" @next="onNext" @select-model="onModelSelect" v-if="current_step === 2" :models="models"
						:form="form"></BuildingSelectStep>
					<BuildingDetailStep :edit-mode="editMode" @next="onNext" v-if="current_step === 3" :form="form" :land="land"
						:rooms="rooms"></BuildingDetailStep>
					<BuildingSummaryStep :edit-mode="editMode" @next="onPay" v-if="current_step === 4" :models="models" :form="form"></BuildingSummaryStep>
				</div>
			</div>
			
			<div class="col-md-6 col-sm-12 order-sm-1 order-md-2">
				<div class="card wow fadeIn" v-if="Object.keys(models).length > 0">
					<model-viewer seamless-poster reveal="auto" auto-rotate camera-controls class="model-view" alt="Item image" id="eepers" ar
						ar-modes="webxr scene-viewer quick-look"
						:src="`https://app.babilu.online${models[form.building_model_id].model_src}`"
						:poster="`https://app.babilu.online${models[form.building_model_id].image_src}`"></model-viewer>
					<h2 class="text-center">{{ models[form.building_model_id].name }}</h2>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import User from "@/api/user"
	import Building from "@/api/building"
	import Land from "@/api/land"
	import "@google/model-viewer"
	import BuildingSelectStep from "../../components/building/BuildingSelectStep";
	import BuildingDetailStep from "../../components/building/BuildingDetailStep";
	import BuildingPlacementStep from "../../components/building/BuildingPlacementStep";
	import BuildingSummaryStep from "../../components/building/BuildingSummaryStep";
	import BuildingInformationStep from "../../components/building/BuildingInformationStep";
	import BuildingLandStep from "../../components/building/BuildingLandStep";
	
	export default {
		name: "BuildingCreate",
		components: {
			BuildingLandStep,
			BuildingInformationStep,
			BuildingSummaryStep,
			BuildingDetailStep,
			BuildingSelectStep,
			BuildingPlacementStep,
		},
		data() {
			return {
				editMode: false,
				current_step: 0,
				form: {
					name: "",
					target_oasis_id: "",
					building_model_id: 5,
					position_x: 0,
					position_z: 0,
					position_r: 0,
					spawn_chunk_x: 0,
					spawn_chunk_y: 0,
					logo_src: "",
					logo_id: "",
					land: [],
				},
				models: {},
				rooms: [],
				land: [],
			}
		},
		methods: {
			getOwnedLand: function () {
				User.indexLandUndeveloped(this.$store.state.wallet_addr).then(resp => this.land = resp.data)
			},
			getOwnedRooms: function () {
				User.indexRooms(this.$store.state.wallet_addr).then(resp => this.rooms = resp.data)
			},
			
			onModelSelect: function (model) {
				this.form.building_model_id = model.id;
			},
			
			onNext: function (data) {
				console.log("next", data)
				this.form = Object.assign(this.form, data);
				this.current_step++;
			},
			
			signMessage: async function (msg) {
				const encodedMessage = new TextEncoder().encode(msg);
				return await window.solana.signMessage(encodedMessage, "utf8")
			},
			
			onPay: function () {
				const msg = JSON.stringify(this.form);
				const encodedMessage = new TextEncoder().encode(msg);
				window.solana.signMessage(encodedMessage, "utf8").then(this.onPayResponse).catch((e) => {
					console.log("Sign err: ", e);
					alert("Transaction aborted")
				});
			},
			
			onPayResponse: function (signed) {
				const payload = Object.assign(Object.assign({}, this.form), {
					signature: signed,
					public_address: this.$store.state.wallet_addr,
					is_custom: false,
					custom_building_src: ""
				});
				
				if (this.editMode) {
					Building.update(this.$route.params.id, payload).then(resp => {
						this.$router.push(`/buildings/${resp.data.token_addr}`)
					}).catch(e => {
						console.log("Update err: ", e);
					});
				} else {
					Land.build(payload.spawn_chunk_x, payload.spawn_chunk_y, payload).then(resp => {
						this.$router.push(`/buildings/${resp.data.token_addr}`)
					})
				}
				
			},
			
			stepClass: function (step) {
				let lg = "col-lg-2"
				if(this.editMode)
					lg = "col-lg-3"
				
				if (this.current_step === step)
					return lg + " col-md-6 col-sm-6 active"
				
				return lg + " col-md-2 col-sm-2"
			},
			
			getModels: function () {
				Building.indexModels().then(resp => {
					for (const m in resp.data) {
						this.models[resp.data[m].id] = resp.data[m]
					}
					console.log("models:", this.models)
				})
			},
			getBuilding: function () {
				Building.show(this.$route.params.id).then(resp => {
					this.form = resp.data
				})
			},
			
			// saveModel: function() {
			// 	document.getElementById("eepers").toBlob({idealAspect: true}).then(r => {
			// 		console.log(r)
			// 		this.saveFile(r, `model.png`)
			// 	})
			// },
			//
			// saveFile: function (blob, filename) {
			// 	if (window.navigator.msSaveOrOpenBlob) {
			// 		window.navigator.msSaveOrOpenBlob(blob, filename);
			// 	} else {
			// 		const a = document.createElement('a');
			// 		document.body.appendChild(a);
			// 		const url = window.URL.createObjectURL(blob);
			// 		a.href = url;
			// 		a.download = filename;
			// 		a.click();
			// 		// setTimeout(() => {
			// 		// 	window.URL.revokeObjectURL(url);
			// 		// 	document.body.removeChild(a);
			// 		// }, 0)
			// 	}
			// }
		},
		
		mounted: function () {
			
			if (this.$route.params.id) {
				console.log("Building edit mode")
				this.editMode = true;
				this.current_step = 1;
				this.getBuilding();
			} else {
				console.log("New building mode")
			}
			
			this.getModels();
			this.getOwnedRooms();
			this.getOwnedLand();
		}
	}
</script>

<style scoped>
	.model-view {
		--poster-color: transparent;
		height: 800px;
		width: 100%;
	}
	
	.step-info, h2, h3 {
		color: white;
		font-weight: bold;
		font-size: 1.2rem;
	}
	
	h3 {
		font-size: 1.1rem;
	}
	
	.step-info span.circle {
		border: 3px solid #0a9fb9;
		display: inline-block;
		background: #0E0E0F;
		color: white;
		text-align: center;
		line-height: 45px;
		height: 50px;
		width: 50px;
		border-radius: 25%;
		transition: all 1s ease-in-out;
	}
	
	.step-info.active span.circle {
		border: 3px solid white;
		background: #0a9fb9;
		color: white;
		transform: scale(1.2);
	}
	
	.step-info span.text {
		margin-left: 15px;
	}
	
	.card {
		height: 100%;
	}

</style>