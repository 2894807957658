<template>
	<div class="card-body">
		<div v-if="!editMode">
			<h2 class="mb-3">Creating A Building</h2>
			
			<p class="mb-3">Buildings can be placed onto land owned by the user to generate revenue through the hosting of other user Oasis instances along with
				advertising placement.</p>
			
			<p class="mb-3">Users are able to create custom sized Oasis to host their own Metaverse's or Apartment rooms within their building based on the
				amout of
				space taken up within the land tile.</p>
			
			<p class="mb-3">Additional land tiles can optionally be staked to the building to create larger buildings and increase capacity of Oasis within the
				building via increasing the height limit.</p>
			
			<p class="mb-5">Once built, users are able to see and enter their building within the main city Oasis explorer and begin to create their own
				internal
				rooms & layouts.</p>
		</div>
		
		<div v-if="editMode" class="mb-3">
			<h2 class="mb-3">Update Building</h2>
			
			<p class="mb-3">The editor will allow you to modify the following information on a given building;</p>
			<ul>
				<li>- Update/Upload building model</li>
				<li>- Update building details</li>
				<li>- Stake additional land</li>
				<li>- Increase Oasis dimensions</li>
			</ul>
		</div>
		
		<button class="btn btn-primary btn-block mt-3" v-on:click="$emit('next')">Start</button>
	</div>
</template>

<script>
	export default {
		name: "BuildingInformationStep",
		props: {
			editMode: Boolean,
		}
	}
</script>

<style scoped>
	h2 {
		color: white;
		font-size: 1.2rem;
	}
	
	li {
		margin-left: 25px;
		color: #7a7a7a;
	}
</style>