<template>
	<div class="card-body">
		<h1>Additional Land <small>(Optional)</small></h1>
		<p class="mb-5">Adding surrounding land tiles to a building allows larger structures to be built. </p>
		
		<form class="form">
			<div class="row">
				<div class="col-8">
					<div class="table-container">
						<table class="table table-sm">
							<thead>
							<tr>
								<th>ID</th>
								<th>X</th>
								<th>Y</th>
								<th></th>
							</tr>
							</thead>
							<tbody>
							<tr class="land" v-for="l in surroundingLand" :key="`s-${l.x}${l.y}`">
								<td><code>{{ l.id ? l.id : '2wci94quHBAAVt1HC4T5SUerZR7699LMb8Ueh3CSVpTX' }}</code></td>
								<td>{{ l.x }}</td>
								<td>{{ l.y }}</td>
								<td>
									<button class="btn btn-primary btn-sm btn-super-sm" :disabled="isLandAdded(l)"
										v-on:click="(e) => {e.preventDefault(); addLand(l)}">
										{{ isLandAdded(l) ? "ADDED" : "ADD" }}
									</button>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				
				<div class="col-4 text-center">
					<h2 class="mb-5">Max Building Size: {{maxBuildingSize}}x{{maxBuildingSize}}</h2>
					<SectorMap v-if="details.land" :center="selected_land" :extra-tiles="land" :tiles="details.land"
						:tile-length="details.land ? details.land.length : 0"></SectorMap>
				</div>
			</div>
			
			<hr>
			<div class="row">
				<div class="col-12">
					<h2 class="mb-3">Placement</h2>
					
					<div class="form-group">
						<label>X: {{ details.position_x }} Tiles</label>
						<input class="form-control" type="range" v-model="details.position_x" min="0" max="9" step="1">
					</div>
					
					
					<div class="form-group">
						<label>Y: {{ details.position_z }} Tiles</label>
						<input class="form-control" type="range" v-model="details.position_z" min="0" max="9" step="1">
					</div>
					
					
					<div class="form-group">
						<label>Rotation: {{ details.position_r }} Degrees</label>
						<input class="form-control" type="range" v-model="details.position_r" min="0" max="360" step="5">
					</div>
					
					<div class="form-group mt-5">
					</div>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-12 text-center">
					<button class="btn btn-primary btn-block" v-on:click="onNext">Next</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import SectorMap from "../land/map/SectorMap";
	
	export default {
		name: "BuildingDetailStep",
		components: {SectorMap},
		props: {
			land: {
				type: Array,
				required: true
			},
			form: {
				type: Object,
				required: true,
			},
			editMode: {
				type: Boolean,
				required: true
			},
		},
		data() {
			return {
				selected_land: {
					x: 0,
					y: 0,
				},
				details: {
					position_x: 0,
					position_y: 0,
					position_z: 0,
					position_r: 0,
					land: [],
				}
			}
		},
		watch: {
			selected_land: function () {
				this.details.land = [];
			}
		},
		computed: {
			maxBuildingSize: function() {
				return Math.floor(Math.sqrt(1 + this.details.land.length)); //1 additional to account for selected_land
			},
			surroundingLand: function () {
				//Below auto adds in the new surrounding tiles
				// return this.land.filter((l) => {
				// 	return this.details.land.filter((l2) => {
				// 		return (l2.x - 1 <= l.x && l.x <= l2.x + 1) && (l2.y - 1 <= l.y && l.y <= l2.y + 1)
				// 	}).length > 0 || (this.selected_land.x - 1 <= l.x && l.x <= this.selected_land.x + 1) && (this.selected_land.y - 1 <= l.y && l.y <= this.selected_land.y + 1)
				// });
				
				return this.land.filter((l) => {
					return (this.selected_land.x - 1 <= l.x && l.x <= this.selected_land.x + 1) && (this.selected_land.y - 1 <= l.y && l.y <= this.selected_land.y + 1)
				});
			},
		},
		methods: {
			isValid: function () {
				return (this.editMode || this.selected_land.id !== -1)
			},
			onNext: function (e) {
				e.preventDefault();
				
				if (!this.isValid()) {
					alert("Invalid form")
					return
				}
				
				let payload = Object.assign({}, this.details)
				
				if (!this.editMode) {
					console.log("sl", this.selected_land);
					payload.land.push(this.selected_land)
				}
				
				this.$emit('next', {
					position_x: parseInt(this.details.position_x),
					position_y: parseInt(this.details.position_y),
					position_z: parseInt(this.details.position_z),
					position_r: parseInt(this.details.position_r),
					land: this.details.land,
				})
			},
			isLandAdded: function (l) {
				if (!this.details.land)
					return false
				
				if (this.selected_land.x === l.x && this.selected_land.y === l.y)
					return true;
				
				return this.details.land.includes(l)
			},
			
			addLand: function (l) {
				console.log("adding land", l);
				this.details.land.push(l)
			}
		},
		beforeMount() {
			this.details = {
				position_x: this.form.position_x,
				position_y: this.form.position_y,
				position_z: this.form.position_z,
				position_r: this.form.position_r,
				land: this.form.land,
			}
			this.selected_land = {
				x: this.form.spawn_chunk_x,
				y: this.form.spawn_chunk_y,
			}
		}
	}
</script>

<style scoped>
	h2 {
		color: white;
		font-size: 1.2rem;
	}
	
	code {
		color: white;
		font-size: 0.8rem;
	}
	
	tr.land {
		vertical-align: middle;
		font-size: 0.8rem;
		line-height: 30px;
	}
	
	.btn-super-sm {
		height: auto;
	}
	
	h1 small {
		font-size: 1rem;
		color: white;
	}
	
	.table-container {
		overflow-y: scroll;
		max-height: 500px;
	}
</style>