<template>
	<div class="loading-screen">
		<div class="loading-pulse">
			<div class="loading">
				<span></span>
				<span></span>
				<span></span>
				<div>
					<h2>Loading Map...</h2>
					<h3>{{progress}}/{{total}}</h3>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LoadingSmall",
		props: {
			progress: Number,
			total: Number,
			background: Boolean
		}
	}
</script>

<style scoped>
	
	
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	
	body {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #111;
		min-height: 100vh;
	}
	
	.loading {
		position: relative;
		width: 200px;
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.loading span {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 2px solid #26B6D4;
		position-events: none;
		animation: animate 5s ease-out infinite;
	}
	
	.loading span:nth-child(1) {
		border-radius: 0% 0% 0% 0% / 25% 25% 25% 25%;
	}
	
	.loading span:nth-child(2) {
		animation-direction: reverse;
		border-radius: 0% 0% 0% 0% / 25% 25% 25% 25%;
	}
	
	.loading span:nth-child(3) {
		animation-delay: 1s;
		animation-duration: 2s;
		border-radius: 0% 0% 0% 0% / 25% 25% 25% 25%;
	}
	
	@keyframes animate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	
	.loading h2 {
		color: #fff;
		font-weight: 500;
	}
	
	.loading-screen {
		z-index: 1;
		position: absolute;
		text-align: center;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(25, 25, 25, 0.7);
		vertical-align: middle;
	}
	
	.loading-screen h1 {
		color: white;
		margin-top: 50px;
	}
	
	.loading-pulse {
		margin: 0;
		padding: 0;
		justify-content: center;
		display: flex;
		align-items: center;
		height: 100%;
	}
	
	.loading-pulse * {
		box-sizing: border-box;
	}
	
	h2 {
		color: #fff;
		font-weight: 500;
	}
	
	h3 {
		margin-top: 10px;
		color: #fff;
		font-weight: 400;
	}
</style>