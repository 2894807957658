<template>
	<div class="container-fluid mt-3 p-4">
		<div class="row">
			<div class="col-6">
				<div class="card">
					<div class="card-body">
						
						<div class="row">
							<div class="col-8">
								<h2 class="">Land Details</h2>
							</div>
							<div class="col-4 text-right">
								<SectorMap v-if="land.token_addr !== undefined" :center="land" :extra-tiles="[]" :tiles="[]" :mapSize="5"
									:loadSurroundingTiles="true"
									:tile-length="1"></SectorMap>
							</div>
						</div>
						
						
						<div class="row">
							<div class="col-12">
								<table class="table mt-3">
									<tbody>
									<tr>
										<td>ID:</td>
										<td>{{ land.token_addr }}</td>
									</tr>
									<tr>
										<td>Owner:</td>
										<td>{{ land.owner_addr }}</td>
									</tr>
									<tr>
										<td>Coords:</td>
										<td>{{ land.x }}, {{ land.y }}</td>
									</tr>
									<tr>
										<td>Mint Date:</td>
										<td>{{ land.created_at }}</td>
									</tr>
									<tr>
										<td>Last Sold:</td>
										<td>{{ land.purchased_at ? land.purchased_at : "-" }}</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="row">
							<div class="col" v-if="land.owner_addr === ''">
								<button class="btn btn-block btn-primary">BUY LAND</button>
							</div>
							<div class="col" v-if="land.owner_addr !== '' && land.owner_addr !== $store.state.wallet_addr">
								<button class="btn btn-block btn-primary">MAKE OFFER</button>
							</div>
							<div class="col" v-if="land.owner_addr === $store.state.wallet_addr">
								<button class="btn btn-block btn-primary">SELL LAND</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-8">
								<h2 class="mb-3">Building</h2>
							</div>
							<div class="col-4 text-right"><img alt="Building Logo" class="building-logo" v-if="building.logo" :src="building.logo.public_uri"></div>
						</div>
						
						<div class="text-center mt-5 pt-5" v-if="!land.Buildings || land.Buildings.length === 0">
							<i>No Building Constructed</i>
							<p class="mt-5" v-if="land.owner_addr === $store.state.wallet_addr">
								<router-link :to="`/buildings/create?land=${land.token_addr}`" v-if="land.owner_addr !== ''" class="btn btn-sm btn-primary btn-table">BUILD ON LAND</router-link>
							</p>
						</div>
						
						<table class="table" v-if="land.Buildings && land.Buildings.length > 0">
							<tbody>
							<tr>
								<td>Name</td>
								<td>{{ building.name }}</td>
							</tr>
							<tr>
								<td>Token Address:</td>
								<td>{{ building.token_addr }}</td>
							</tr>
							<tr>
								<td>Owner(s):</td>
								<td>
									<router-link :to="`/players/${l.owner_addr}`" class="owner" v-for="l in building.land" :key="l.id">{{ l.owner_addr }}
									</router-link>
								</td>
							</tr>
							<tr>
								<td>Target Oasis:</td>
								<td><router-link :to="`/oasis/${oasis.token_id}`">{{ oasis.name }}</router-link></td>
							</tr>
							<tr>
								<td>City Position:</td>
								<td>{{ building.spawn_chunk_x }},{{ building.spawn_chunk_y }} + {{ building.position_x }},{{ building.position_z }}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h2 class="mb-3">History</h2>
						
						<table class="table table-sm">
							<thead>
							<tr>
								<th>Date</th>
								<th>Action</th>
								<th>Source</th>
								<th>Recipient</th>
								<th>Details</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>{{ land.created_at }}</td>
								<td>Mint</td>
								<td><code>0x0</code></td>
								<td><code>{{ $store.state.wallet_addr }}</code></td>
								<td>-</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Land from "@/api/land"
	import Room from "@/api/room"
	import Building from "@/api/building"
	import SectorMap from "../../components/land/map/SectorMap";
	
	export default {
		name: "LandShow",
		components: {SectorMap},
		data() {
			return {
				building: {},
				oasis: {},
				land: {}
			}
		},
		methods: {
			getLand: function () {
				Land.show(this.$route.params.x, this.$route.params.y).then(resp => {
					this.land = resp.data;
					this.getBuilding();
				})
			},
			getBuilding: function () {
				Building.show(this.land.Buildings[0].token_addr).then(resp => {
					this.building = resp.data
					this.getOasis();
				})
			},
			getOasis: function () {
				Room.show(this.building.target_oasis_id).then(resp => this.oasis = resp.data)
			}
		},
		mounted() {
			this.getLand();
		}
	}
</script>

<style scoped>
	h2 {
		color: white;
		font-size: 1.2rem;
	}
	
	i {
		font-style: italic;
		color: #0a9fb9;
	}
	
	.card {
		height: 100%;
	}
	.card-body {
		padding-top: 0;
	}
	
	.building-logo {
		max-height: 150px;
	}
</style>