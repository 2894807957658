<template>
	<div class="tt card" ref="tooltip" v-show="show" @mouseover="hover = true" @mouseleave="hover = false"
	>
		<div class="card-body container-master-fluid">
			<div class="row">
				<div class="col-3 pr-0">
					<div class="image-container" v-show="!hover">
						<img class="img-fluid" :src="validImage" alt="tile image">
					</div>
					<button v-show="hover" class="btn btn-primary btn-go" v-on:click="onClick">
						{{ tile.id === "" || selected.length > 1 ? 'ADD' : tile.owner === "" ? 'CLAIM' : 'VISIT' }}
					</button>
				</div>
				<div class="col-9 pl-0">
					<div class="noline">
						<div class="row">
							<div class="col">
								<h4>
									<router-link :to="uri">{{ tile.name }}</router-link>
								</h4>
							</div>
							<!--							<div class="col-4 text-center"><h4>{{ Math.sqrt(selected.length).toFixed(0) }}x{{ Math.sqrt(selected.length).toFixed(0) }} <small>({{-->
							<!--									selected.length-->
							<!--								}})</small></h4></div>-->
							<div class="col-4 text-center">
								<h4><small>{{ chunk[0] }},{{ chunk[1] }}</small></h4>
							</div>
						</div>
						<code>{{ tile.owner }}</code>
					</div>
					<div class="row detail">
						<div class="col">
							<h6><small>{{ sector }} Sector</small></h6>
							<!--							<button v-on:click="onVisit" class="btn btn-primary btn-sm btn-tooltip">VISIT</button>-->
						</div>
						<div class="col">
							<h6><small>{{ tile.guild.name }}</small></h6>
						</div>
						<div class="col-3 text-left">
<!--							<h6 v-if="tile.owner !== ''">Points: <small>{{ tilePoints }}</small></h6>-->
							<button v-on:click="onBuy" v-if="tile.owner === ''" class="btn btn-primary btn-sm btn-tooltip">BUY
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Logo from "@/api/logo"

export default {
	name: "Tooltip",
	data() {
		return {
			hover: false,
			el: null
		}
	},
	props: {
		selected: {
			type: Array,
			default: function () {
				return []
			}
		},
		tile: {
			type: Object,
			default: function () {
				return {
					id: "",
					building_id: "",
					image: "/lounge/virtual_room_logo.png",
					name: "Empty Land",
					owner: "",
					target_oasis_id: "0x0000000000000000000000000000000000000000",
					guild: {"name": ""},
					points: 0,
				}
			}
		},
		sectors: {
			type: Object,
			default: function () {
				return {}
			}
		},
		show: {
			type: Boolean,
			default: function () {
				return false
			}
		},
		chunk: {
			type: Array,
			default: function () {
				return [0, 0];
			}
		},
		position: {
			type: Object,
			default: function () {
				return {
					x: 0,
					y: 0,
				}
			}
		}
	},
	computed: {
		uri: function () {
			if (this.tile.building_id === '' || this.tile.building_id === undefined) {
				return `/land/${this.chunk[0]}/${this.chunk[1]}`
			}

			return `/buildings/${this.tile.building_id}`
		},

		tilePoints: function () {
			return this.tile.points;
		},
		validImage: function () {
			return this.tile.image || "/lounge/virtual_room_logo.png";
		},
		sector: function () {
			if (this.sectors === null)
				return "";

			const skey = Math.floor((this.position.x) / 80) + (Math.floor((this.position.y / 80)) * 5)
			if (this.sectors[skey])
				return this.sectors[skey].name

			return "Unknown Sector"
		}
	},
	watch: {
		position: function () {
			this.updatePosition();
		},
		tile: function() {
			this.hover = this.tile.owner === ''
		}
	},
	methods: {
		updatePosition: function () {
			// console.log("Updating tooltip", this.position);
			this.loadImage();

			this.el.style.left = (this.position.x - 350) + "px";
			this.el.style.top = (this.position.y - 150) + "px";
		},

		loadImage: function () {
			console.log("Loading img: ", this.tile.logo_id);
			if (this.tile.logo_id === -1 || this.tile.logo_id === undefined)
				return

			Logo.showID(this.tile.logo_id).then(resp => {
				this.tile.image = resp.data.public_uri
			}).catch(e => {
				console.log("Unable to get logo", e)
			})
		},

		onClick: function () {
			console.log("Tile", this.tile, this.selected);

			if (this.tile.id !== "" && this.selected.length === 1) {
				console.log("Going to", `/oasis/${this.tile.token_addr}/visit`, {
					"r": this.$router
				})
				this.$router.push(`/oasis/${this.tile.token_addr}/visit`)
				return;
			}

			if (this.tile.building_id === "" || this.tile.building_id === undefined) {
				console.log("Going to", `/land/${this.chunk[0]}/${this.chunk[1]}`, this.$router)
				this.$router.push(`/land/${this.chunk[0]}/${this.chunk[1]}`)
				return
			}

			for (let i = 0; i < this.selected.length; i++)
				this.$emit("add-basket", this.selected[i])

			// this.$emit("add-basket", this.chunk)
		},

		onBuy: function () {
			console.log("onBuy", this.chunk)
			this.$router.push(`/marketplace/land/${this.chunk[0]}/${this.chunk[1]}`)
		},

		onVisit: function (e) {
			e.preventDefault()
			console.log("onVisit", this.tile.building_id)
			if (this.tile.building_id === "" || this.tile.building_id === undefined)
				return this.$router.push(`/land/${this.chunk[0]}/${this.chunk[1]}`)

			return this.$router.push(`/buildings/${this.tile.building_id}`)
		}
	},
	mounted() {
		this.el = this.$refs.tooltip
	}
}
</script>

<style scoped>
.tt {
	position: absolute;
	z-index: 10;
	top: 15px;
	left: 35%;
	border: 1px solid black;
	border-radius: 4px;
	width: 500px;
}

.detail {
	font-size: 0.8rem;
}

.card {
	background: #0E0E0F;
	padding: 5px;
	/* box-shadow: inset 0px 10px 20px rgb(255 255 255 / 25%), inset 0px -10px 20px rgb(255 255 255 / 25%); */
	box-shadow: 1px 1px 8px #26b6d4;
	border-radius: 14px;
	text-align: left;
}

.card-body:before {
	content: revert;
}

.card-body {
	padding: 5px;
}

code {
	font-size: 72%;
	font-weight: bold;
	color: #166375;
}

h4 {
	margin-bottom: 0;
}

h4, h5, h6 {
	color: white;
}

.noline {
	line-height: 1;
	margin-bottom: 15px;
}

.btn-go {
	padding: 0;
	border-radius: 40px;
}

.btn-go, .image-container {
	position: relative;
	width: 75px;
	height: 75px;
}

img {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
}

.btn-tooltip {
	max-height: 25px;
	line-height: 1;
}
</style>