<template>
	<div class="card-body">
		<h1 class="mb-5">Building Details</h1>
		
		<form class="form">
			<div class="form-group">
				<label>Building Name: </label>
				<input class="form-control" type="text" v-model="details.name">
			</div>
			
			<div class="form-group">
				<label class="mb-2">Building Logo: </label>
				<IndexLogoUploads  @set-active="onLogoUpdate" :active="details.logo_src"></IndexLogoUploads>
			</div>
			
			<div class="form-group">
				<label>Target Oasis: </label>
				<select class="form-control" v-model="details.target_oasis_id">
					<option v-for="l in rooms" :key="l.id" :value="l.token_id">{{ l.name }}</option>
				</select>
			</div>
			<div class="form-group mt-5">
				<button class="btn btn-primary btn-block" v-on:click="onNext" :disabled="!isValid()">Next</button>
			</div>
		</form>
	</div>
</template>

<script>
	import IndexLogoUploads from "../upload/IndexLogoUploads";
	export default {
		name: "BuildingDetailStep",
		components: {IndexLogoUploads},
		props: {
			land: {
				type: Array,
				required: true
			},
			rooms: {
				type: Array,
				required: true
			},
			form: {
				type: Object,
				required: true
			},
			editMode: {
				type: Boolean,
				required: true
			},
		},
		data() {
			return {
				selected_land: {
					id: -1,
				},
				details: {
					name: "",
					logo_src: "",
					logo_id: "",
					target_oasis_id: "",
				}
			}
		},
		computed: {},
		methods: {
			isValid: function () {
				console.log("isValid: ", this.details);
				return this.details.name.length > 3 && this.details.logo_id.length > 3 && this.details.target_oasis_id.length > 0;
			},
			
			onLogoUpdate: function(logo) {
				console.log("setting active logo: ", logo);
				this.details.logo_src = logo.public_uri //Update ui
				this.details.logo_id = logo.uuid
			},
			
			onNext: function (e) {
				e.preventDefault();
				
				if (!this.isValid()) {
					alert("Invalid form")
					return
				}
				
				let payload = Object.assign({}, this.details)
				
				this.$emit('next', payload)
			}
		},
		mounted() {
			console.log("editMode: ", this.editMode);
		},
		beforeMount() {
			this.details = {
				name: this.form.name,
				logo_id: this.form.logo_id,
				target_oasis_id: this.form.target_oasis_id,
			}
			
			if (this.form.logo) {
				this.details.logo_src = this.form.logo.public_uri;
			}
		}
	}
</script>

<style scoped>

</style>