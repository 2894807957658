<template>
	<div class="card-body">
		<h1>Select Building</h1>
		<div class="row">
			<div class="col-sm-6 col-md-4 col-lg-3 mt-5 text-center" v-for="(model, key) in models" :key="key">
				<BuildingModelCard @select-model="onModelSelect" :model="model"></BuildingModelCard>
				<span class="selected" v-if="model.id === form.building_model_id">SELECTED</span>
			</div>
			<div class="col-sm-6 col-md-4 col-lg-3 mt-5 text-center">
				<h3 class="text-white mt-5">Custom Buildings Coming Soon!</h3>
			</div>
			<div class="col-12 mt-5">
				<button class="btn btn-primary btn-block mt-5" v-on:click="$emit('next')">Next</button>
			</div>
		</div>
	</div>
</template>

<script>
	import BuildingModelCard from "./BuildingModelCard";
	
	export default {
		name: "BuildingSelectStep",
		components: {BuildingModelCard},
		props: {
			models: {
				type: Object,
				required: true
			},
			form: {
				type: Object,
				required: true
			}
		},
		methods: {
			onModelSelect: function (model) {
				this.$emit("select-model", model);
			},
		}
	}
</script>

<style scoped>
	.card {
		padding-top: 0;
		overflow-x: hidden;
		overflow-y: auto;
	}
	
	.selected {
		font-size: 0.8rem;
		color: #0a9fb9;
	}
</style>