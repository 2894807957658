<template>
	<div class="container-fluid p-4 mt-3">
		<div class="row">
			<div class="col-6">
				<div :class="tabClass(0)" v-on:click="selectTab(0)"><h1>Buildings</h1></div>
			</div>
			<div class="col-6">
				<div :class="tabClass(1)" v-on:click="selectTab(1)"><h1>Land</h1></div>
			</div>
		</div>
		<div class="row">
			<BuildingIndex v-if="tab === 0"></BuildingIndex>
			<LandListIndex v-if="tab === 1"></LandListIndex>
		</div>
	</div>
</template>

<script>
	import LandListIndex from "../land/LandListIndex";
	import BuildingIndex from "../buildings/BuildingIndex";
	
	export default {
		name: "EstateContainer",
		components: {BuildingIndex, LandListIndex},
		data() {
			return {
				tab: 0,
			}
		},
		methods: {
			tabClass: function (tab) {
				const base = "text-center tab"
				
				if (tab === this.tab)
					return `${base} active`
				
				return base
			},
			selectTab: function (tab) {
				this.tab = tab;
			}
		}
	}
</script>

<style scoped>
	.tab {
		border: 2px solid black;
		background: #0E0E0F;
		line-height: 3rem;
		cursor: pointer;
		border-radius: 8px;
	}
	
	.tab.active {
		background: transparent;
		border: 2px solid #0a9fb9;
	}
	
	.tab h1 {
		color: white;
		font-size: 1.3rem;
		font-weight: bold;
		text-transform: uppercase;
	}
</style>