<template>
	<div class="map row">
		<div class="map-container">
			<div id="canvas-container"></div>
		</div>
		<SectorTooltip v-if="hoverSector !== null && activeSector === null && !controls.dragging" :active-sector="sectors[hoverSector]" :mouse="mouseRaw"></SectorTooltip>
		<h1 class="title">AlphaBatem Land</h1>

		<div class="sector-container text-center">
			<h5>Sectors</h5>
			<div class="sector" v-for="sector in sectors" :key="sector.name" v-on:click="onSectorClicked(sector)"><h6>
				{{ sector.name }}</h6></div>
		</div>

		<div class="back-container" v-if="activeSector !== null">
			<div class="sector-map-container">
				<LandIndexSector :activeSector="activeSector" @navigate="onNavigate" @back="onBack"></LandIndexSector>
			</div>
		</div>
	</div>
</template>

<script>
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import SectorTooltip from "../../components/land/map/SectorTooltip";
import sectorJson from "/public/map/sectors.json";
import {TWEEN} from "three/examples/jsm/libs/tween.module.min";
import LandIndexSector from "../land/LandIndexSector";

export default {
	name: "SectorMap",
	components: {LandIndexSector, SectorTooltip},
	data() {
		return {
			renderer: null,
			clock: null,
			camera: null,
			scene: null,
			raycast: null,
			mouse: null,
			controls: null,
			mouseRaw: {},

			hoverSector: null,

			activeObject: null,
			activeSector: null,

			moving: false,
			moveTimeout: null,

			sectors: {},

			//TODO Assign colors to faces correctly
			//TODO Open minimap of the sector (calc tiles)
			//TODO Show map of central (Diamond sector)
			//TODO Intro animation
			//TODO Show sector details on hover (add this on raycast within render -> if hovering then show tooltip)
		}
	},
	computed: {},
	methods: {

		onBack() {
			const to = new THREE.Vector3(-50, 20, 30);


			if (this.activeObject !== null)
				this.activeObject.opacity = 0.8;

			this.activeSector = null;
			this.activeObject = null;
			this.scene.visible = true;

			new TWEEN.Tween(this.camera.position).to(to, 1000).easing(TWEEN.Easing.Cubic.Out).onUpdate((e) => {
				this.camera.position.copy(e);
			}).onComplete(() => {
				this.controls.enabled = true;
			}).start();
		},

		onNavigate: function (uri) {
			console.log("onNavigate", uri)
			this.$router.push(uri)
		},

		load() {
			this.sectors = sectorJson


			//Renderer
			this.renderer = new THREE.WebGLRenderer({antialias: true});
			this.renderer.setPixelRatio(window.devicePixelRatio);
			this.renderer.setSize(window.innerWidth, window.innerHeight);
			document.getElementById("canvas-container").appendChild(this.renderer.domElement);

			this.clock = new THREE.Clock()
			this.scene = new THREE.Scene();

			//Setup camera
			this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
			this.camera.position = new THREE.Vector3(-50, 20, 30);
			this.camera.lookAt(this.scene.position);

			new THREE.TextureLoader().load("/map/hdr.png", (texture) => {
				this.scene.background = texture;
			});


			/*** Lights ***/
			this.scene.add(new THREE.AmbientLight('white', 4));

			const light = new THREE.SpotLight('white', 1, 0, Math.PI / 5, 0.3);
			light.position.set(0, 150, 100);
			light.target.position.set(0, 0, 0);


			this.scene.add(light);

			/*** Camera controls ***/
			this.controls = new OrbitControls(this.camera, this.renderer.domElement);
			this.controls.autoRotate = true
			this.controls.autoRotateSpeed = 1
			this.controls.enablePan = false;

			// this.controls.addEventListener('start', () => {
			// 			console.log("console start")
			// 			clearInterval(this.moveTimeout)
			// 			this.moving = true;
			// 		}
			// );
			//
			// this.controls.addEventListener('end', () => {
			// 	this.moveTimeout = setTimeout(() => {
			// 		this.moving = false;
			// 	}, 500);
			// });


			// this.scene.add(new THREE.AxisHelper(20));

			this.raycast = new THREE.Raycaster();
			this.mouse = new THREE.Vector2();

			this.addCubes(2);
		},

		addCubes(dimensions) {
			const cubeSize = 10,
					spacing = 0.2;

			const increment = cubeSize + spacing;
			// const maxExtent = (cubeSize * dimensions + spacing * (dimensions - 1)) / 2;
			const allCubes = [];

			const positionOffset = (dimensions - 1) / 2;
			let idx = 0;
			for (let i = 0; i < dimensions; i++) {
				for (let j = 0; j < dimensions; j++) {
					for (let k = 0; k < dimensions; k++) {

						const x = (i - positionOffset) * increment,
								y = (j - positionOffset) * increment,
								z = (k - positionOffset) * increment;

						// console.log(idx);
						const cube = this.newCube(cubeSize, x, y, z, idx);
						allCubes.push(cube);
						idx++;
					}
				}
			}
		},

		newCube(cubeSize, x, y, z, idx) {
			// x,y,z are the coordinates of the center of the cube
			//we have 3 faces

			const startIdx = idx * 3; //3 Faces per cube


			const texture = new THREE.TextureLoader().load("/map/mat_3.png")
			texture.wrapS = THREE.RepeatWrapping;
			texture.wrapT = THREE.RepeatWrapping;
			console.log(texture);

			console.log("newCube", x > 0, y > 0, z > 0);


			const x1 = this.sectors[startIdx].color
			const x2 = this.sectors[startIdx + 1].color
			const x3 = this.sectors[startIdx + 2].color
			const x4 = 0xfffff
			const x5 = 0xfffff
			const x6 = 0xfffff

			const colours = [x1, x2, x3, x4, x5, x6]
			const faceMaterials = colours.map(function (c) {
				//TODO Add Map for splitting into mini squares
				return new THREE.MeshLambertMaterial({
					color: c,
					map: texture,
					transparent: true,
					opacity: 0.9,
					emissive: "black"
				});
			})

			const cubeGeometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
			const cube = new THREE.Mesh(cubeGeometry, faceMaterials);
			cube.castShadow = true;

			cube.position.set(x, y, z)
			cube.name = "cube#" + idx;
			cube.rubikPosition = cube.position.clone();
			cube.userData.idx = idx

			this.scene.add(cube);
			return cube
		},


		render() {

			this.hoveringCube()

			this.controls.update()

			TWEEN.update()

			this.renderer.render(this.scene, this.camera);
			requestAnimationFrame(this.render);
		},

		quit() {
			const c = document.querySelector("canvas")
			if (c !== null)
				c.remove()
		},

		/**
		 * Listeners
		 */

		onPointerMove(event) {

			// calculate pointer position in normalized device coordinates
			// (-1 to +1) for both components

			this.mouseRaw = event
			this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
			this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
		},

		hoveringCube() {
			// calculate objects intersecting the picking ray
			this.raycast.setFromCamera(this.mouse, this.camera);
			const intersects = this.raycast.intersectObjects(this.scene.children);

			if (intersects.length <= 0) {
				this.hoverSector = null;
				return;
			}

			const face = intersects[0].face.materialIndex;

			this.hoverSector = intersects[0].object.userData.idx + face;
		},

		onClick(e) {
			e.preventDefault()
			console.log("Click: moving: ", this.moving, e)

			if (this.moving)
				return;

			// update the picking ray with the camera and pointer position
			this.raycast.setFromCamera(this.mouse, this.camera);

			// calculate objects intersecting the picking ray
			const intersects = this.raycast.intersectObjects(this.scene.children);

			if (intersects.length <= 0)
				return;

			const i = 0;
			console.log("isec", intersects[i])
			const face = intersects[i].face.materialIndex;
			// intersects[i].object.material[face].emissive.set(0xff0000);

			//TODO Get cube ID, then add on materialIndex to get our Sector ID
			const sector = intersects[i].object.userData.idx + face;
			this.onSectorClicked(this.sectors[sector])

			this.activeObject = intersects[i].object.material[face]
			// this.activeObject.color.set(0xff0000);
			this.activeObject.opacity = 1;

			console.log("face", {
				camera: this.camera.position,
				obj: intersects[i].object,
			})
			this.controls.enabled = false;
			const to = intersects[i].object.position.clone();
			to.x = -25;


			new TWEEN.Tween(this.camera.position).to(to, 1000).easing(TWEEN.Easing.Cubic.Out).onUpdate((e) => {
				this.camera.position.copy(e);
			}).onComplete(() => {
				this.controls.enabled = true;
			}).start();
		},


		onSectorClicked(sector) {
			console.log("Sector clicked", sector)
			this.activeSector = sector;

			//TODO Open Sector Map

		}
	},
	mounted() {
		this.load();
		this.render();
		window.addEventListener('pointermove', this.onPointerMove);
		this.renderer.domElement.addEventListener('mouseup', this.onClick);
		// window.addEventListener( 'mouseup', this.onClick );
	},
}
</script>

<style scoped>
.title {
	position: absolute;
	top: 2%;
	color: white;
	width: 100%;
	text-align: center;
	font-size: 3rem;
}

.sector-container {
	position: absolute;
	top: 2%;
	right: 2%;
	border: 1px solid white;
	border-radius: 9px;
	background: rgba(25, 25, 25, 0.8);
	color: white;
	min-width: 140px;
	padding-bottom: 10px;
}

.sector-container > .sector {
	padding-left: 5%;
	padding-right: 5%;
	cursor: pointer;
}

h5 {
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 1%;
}

h5, h6 {
	color: white;
}


.sector-container > .sector:hover {
	background: grey;
}

.back-container {
	position: absolute;
	top: 0;
	left: 3%;
	width: 97%;
	height: 100%;
	opacity: 1;
	background: rgba(25, 25, 25, 0.8);


	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s; /* Firefox < 16 */
	-ms-animation: fadein 1s; /* Internet Explorer */
	-o-animation: fadein 1s; /* Opera < 12.1 */
	animation: fadein 1s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>