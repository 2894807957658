import { render, staticRenderFns } from "./UpgradeContainer.vue?vue&type=template&id=177ebef0&scoped=true&"
import script from "./UpgradeContainer.vue?vue&type=script&lang=js&"
export * from "./UpgradeContainer.vue?vue&type=script&lang=js&"
import style0 from "./UpgradeContainer.vue?vue&type=style&index=0&id=177ebef0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "177ebef0",
  null
  
)

export default component.exports