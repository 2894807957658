import {api, apiHelper} from "./api";

export class Building {
	prefix() {
		return "buildings"
	}

	index(cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexModels(cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`/models/buildings?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexRooms(uid) {
		return api.get(`${this.prefix()}/${uid}/rooms`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	update(uid, obj) {
		return api.put(`${this.prefix()}/${uid}`, obj)
	}

	delete(uid) {
		return api.delete(`${this.prefix()}/${uid}`)
	}
}

export default new Building()