<template>
	<div class="card-body">
		<h1 class="mb-3">Summary</h1>
		
		<table class="table">
			<tbody>
			<tr>
				<td>Logo</td>
				<td class="text-center"><img class="building-logo" :src="form.logo_src"></td>
			</tr>
			<tr>
				<td>Name</td>
				<td>{{ form.name }}</td>
			</tr>
			<tr>
				<td>Model:</td>
				<td>{{ models[form.building_model_id].name }}</td>
			</tr>
			<tr>
				<td>Target Oasis:</td>
				<td>{{ form.target_oasis_id }}</td>
			</tr>
			<tr>
				<td>Land:</td>
				<td>{{ form.spawn_chunk_x }},{{ form.spawn_chunk_y }}</td>
			</tr>
			<tr>
				<td>Additional Tiles:</td>
				<td>{{ form.land.length}}</td>
			</tr>
			<tr>
				<td>Position:</td>
				<td>{{ form.position_x }},{{ form.position_z }}</td>
			</tr>
			<tr>
				<td>Rotation:</td>
				<td>{{ form.position_r }} Degrees</td>
			</tr>
			</tbody>
		</table>
		
		<div class="cost-breakdown" v-if="!editMode">
			<h2 class="mt-5 mb-3">Cost Breakdown</h2>
			<table class="table">
				<tbody>
				<tr>
					<td>Construction Cost:</td>
					<td class="text-right">1.000 SOL</td>
					<td class="text-right"><i>186.24 USDT</i></td>
				</tr>
				<tr>
					<td>Building Cost:</td>
					<td class="text-right">1.000 SOL</td>
					<td class="text-right"><i>186.24 USDT</i></td>
				</tr>
				<tr>
					<td>Rent Exemption Cost:</td>
					<td class="text-right">0.264 SOL</td>
					<td class="text-right"><i>49.17 USDT</i></td>
				</tr>
				<tr class="total">
					<td>Total Cost:</td>
					<td class="text-right">2.264 SOL</td>
					<td class="text-right"><i>421.65 USDT</i></td>
				</tr>
				</tbody>
			</table>
		</div>
		
		<button class="btn btn-primary btn-block mt-5" v-on:click="$emit('next')">Confirm & Build</button>
	</div>
</template>

<script>
	export default {
		name: "BuildingSummaryStep",
		props: {
			form: {
				type: Object,
				required: true
			},
			models: {
				type: Object,
				required: true
			},
			editMode: {
				type: Boolean,
				required: true
			}
		}
	}
</script>

<style scoped>
	h2 {
		color: #0a9fb9;
		font-size: 1.2rem;
	}
	
	.total {
		color: #0a9fb9;
		font-weight: bold;
	}
	
	i {
		color: #a1a1a1;
		font-style: italic;
	}
	
	.building-logo {
		max-height: 100px;
	}
</style>