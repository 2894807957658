<template>
	<div class="index">
		<div class="container mt-5">
			<h1>Oasis Create</h1>
			<div class="col-12">
				<form class="form mt-3">
					<div class="form-group">
						<label>Room Name</label>
						<input type="text" v-model="form.name" class="form-control" placeholder="Enter room name">
					</div>
					<div class="form-group">
						<label>Description</label>
						<input type="text" v-model="form.description" class="form-control" placeholder="Brief description">
					</div>
					<div class="form-group">
						<label>Max Capacity</label>
						<input type="number" min="1" max="1024" step="1" v-model="form.max_capacity" class="form-control">
					</div>
					<div class="form-group">
						<label>Category</label>
						<select class="form-control" v-model="form.category_id">
							<option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
						</select>
					</div>
					<div class="form-group">
						<label>Location</label>
						<select class="form-control" v-model="form.location_id">
							<option v-for="location in locations" :value="location.id" :key="location.id">{{ location.name }}
								({{ location.dimensions.width }}x{{ location.dimensions.length }})
							</option>
						</select>
					</div>
					<div class="row">
						<div class="col-6 form-group">
							<label>Width</label>
							<input type="number" min="0" :max="locationMax" step="1" v-model="form.width" class="form-control">
						</div>
						<div class="col-6 form-group">
							<label>Length</label>
							<input type="number" min="0" :max="locationMax" step="1" v-model="form.length" class="form-control">
						</div>
					</div>
					<div class="row">
						<div class="col-6 form-group">
							<label>Charge Entry</label>
							<input type="checkbox" v-model="form.charge_entry" class="form-control">
						</div>
						<div class="col-6 form-group">
							<label>Entry Fee</label>
							<input type="number" min="0" step="0.1" v-model="form.entry_fee" class="form-control">
						</div>
					</div>
					
					<div class="row">
						<div class="col-3 form-group">
							<label>Show Walls</label>
							<input type="checkbox" v-model="form.show_walls" class="form-control">
						</div>
						
						<div class="col-3 form-group">
							<label>Wall Color</label>
							<input type="text" v-model="form.details.wall_color" class="form-control" @focus="showPicker('wall')">
							<Sketch v-show="pickers.wall" v-model="colors.wall" @input="updateWallColor"/>
						</div>
						<div class="col-3 form-group">
							<label>Floor Color</label>
							<input type="text" v-model="form.details.floor_color" class="form-control" @focus="showPicker('floor')">
							<Sketch v-show="pickers.floor" v-model="colors.floor" @input="updateFloorColor"/>
						</div>
						<div class="col-3 form-group">
							<label>Ambient Color</label>
							<input type="text" v-model="form.details.ambient_color" class="form-control" @focus="showPicker('ambience')">
							<Sketch v-show="pickers.ambience" v-model="colors.ambience" @input="updateAmbientColor"/>
						</div>
					</div>
					
					<div class="row">
						<button v-on:click="createRoom" class="btn btn-primary btn-block mt-3">Create</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import {Sketch} from "vue-color"
	import Room from "@/api/room"
	
	export default {
		name: "CreateRoom",
		components: {
			Sketch
		},
		data() {
			return {
				pickers: {
					wall: false,
					floor: false,
					ambience: false,
				},
				colors: {
					wall: { hex: "#000000"},
					floor: { hex: "#111111"},
					ambience: { hex: "#222222"},
				},
				locations: [
					{name: "AlphaBatem Towers (Free)", id: 1, rent: 0, dimensions: {width: 8, length: 8}},
					{name: "AlphaBatem Towers (Outskirts)", id: 2, rent: 200, dimensions: {width: 32, length: 32}},
					{name: "AlphaBatem Towers (Downtown)", id: 3, rent: 500, dimensions: {width: 64, length: 64}},
					{name: "AlphaBatem Towers (Central)", id: 4, rent: 1000, dimensions: {width: 128, length: 128}},
				],
				categories: [
					{name: "Undeveloped", id: 0},
					{name: "Shopping", id: 1},
					{name: "Sponsor", id: 2},
					{name: "Gaming", id: 3},
					{name: "Gambling (KYC)", id: 4},
					{name: "Entertainment", id: 5},
					{name: "Events", id: 6},
					{name: "Open Space", id: 7},
					{name: "18+ (KYC)", id: 8},
				],
				form: {
					name: "",
					description: "",
					max_capacity: 32,
					category_id: 0,
					location_id: 0,
					width: 8,
					length: 8,
					show_walls: true,
					charge_entry: false,
					entry_fee: 0,
					details: { //Dynamically generated on submit
						wall_color: "",
						floor_color: "",
						ambient_color: "",
					}
				}
			}
		},
		computed: {
			locationMax: function () {
				for (let location in this.locations) {
					if (location.id === this.form.location_id) {
						return Math.min(location.width, location.length)
					}
				}
				
				return 8
			}
		},
		methods: {
			createRoom: function (e) {
				e.preventDefault()
				let payload = this.form
				
				payload.details = this.buildDetails()
				
				Room.create(payload).then(r => {
					console.log("Create resp: ", r.data)
				})
			},
			
			
			buildDetails: function () {
				return {
					wall_color: this.colors.wall.hex,
					floor_color: this.colors.floor.hex,
					ambient_color: this.colors.ambience.hex,
				}
			},
			
			indexLocations: function () {
			},
			indexCategories: function () {
			},
			
			
			showPicker(picker) {
				document.addEventListener('click', this.documentClick);
				this.hidePicker()
				this.pickers[picker] = true;
			},
			hidePicker() {
				document.removeEventListener('click', this.documentClick);
				this.pickers["wall"] = false;
				this.pickers["floor"] = false;
				this.pickers["ambience"] = false;
			},
			documentClick() {
				this.hidePicker()
			},
			updateWallColor(color) {
				this.updateColor("wall_color", color)
			},
			updateFloorColor(color) {
				this.updateColor("floor_color", color)
			},
			updateAmbientColor(color) {
				this.updateColor("ambient_color", color)
			},
			
			updateColor(key, color) {
				this.form.details[key] = color.hex
			}
		},
		mounted() {
			this.indexLocations()
			this.indexCategories()
		}
	}
</script>

<style scoped>
	h1 {
		font-size: 2rem;
	}
	
	h1, form {
		color: white;
	}
</style>