<template>
	<div class="container-fluid mt-3 p-4">
		<div class="row mt-3">
			<div class="col-8">
				<h1>Buildings <small>
					<router-link to="/buildings/create" class="btn-none btn-sm">NEW</router-link>
				</small></h1>
			</div>
			<div class="col-md-3 col-sm-11">
				<input class="form-control form-control-lg" placeholder="Search via Name,Address or ID" v-model="search">
			</div>
			<div class="col-1">
				<button class="btn btn-primary">Search</button>
			</div>
		</div>
		
		<div class="room-table mt-3">
			<table class="table table-striped">
				<thead>
				<tr>
					<th :key="h.name" v-for="(h) in headers" :colspan="h.span" :class="h.class">{{ h.name }}</th>
				</tr>
				</thead>
				<tbody>
				<tr :key="r" v-for="(row,r) in filteredData">
					<!--                        <td><a :href="`https://opensea.io/assets/${row.saga}/${row.id}`">{{row.id.substring(row.id.length - 16)}}</a></td>-->
					<!--					<td>{{ row.token_addr }}</td>-->
					<td class="img-td"><img v-if="row.logo" class="table-preview" :src="`http://localhost:9093/static/uploads/logos/${row.logo.uuid}.${row.logo.extension}`"></td>
					<td>{{ row.spawn_chunk_x }}, {{ row.spawn_chunk_y }}</td>
					<td>{{ row.name }}</td>
					<td>
						<router-link class="oasis-link" v-if="rooms[row.target_oasis_id]" :to="`/oasis/${row.target_oasis_id}`">{{ rooms[row.target_oasis_id].name }}</router-link>
						<span v-if="!rooms[row.target_oasis_id]">-</span>
					</td>
					<td class="text-center">{{ row.land.length }}</td>
					<td class="text-center">
						<router-link class="btn btn-primary btn-table btn-sm mx-2" :to="`/buildings/${row.token_addr}`">View</router-link>
						<router-link class="btn btn-secondary btn-table btn-sm mx-2" :to="`/buildings/${row.token_addr}`">Visit</router-link>
						<!--						<router-link class="btn btn-primary btn-table btn-sm mx-2" :to="`/buildings/${row.token_addr}/rooms`">Rooms</router-link>-->
						<!--						<router-link class="btn btn-primary btn-table btn-sm mx-2" disabled="true" :to="`/buildings/${row.token_addr}/sell`">Sell</router-link>-->
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	// import Land from "@/api/land"
	import User from "@/api/user"
	
	export default {
		name: "BuildingIndex",
		
		data() {
			return {
				headers: [
					// {name: "ID", span: 1, class: ""},
					{name: "Logo", span: 1, class: ""},
					{name: "Coords", span: 1, class: ""},
					{name: "Name", span: 1, class: ""},
					{name: "Target Oasis", span: 1, class: ""},
					{name: "Land Staked", span: 1, class: "text-center"},
					{name: "Actions", span: 1, class: "text-center"}
				],
				data: [],
				rooms: {},
				search: "",
			}
		},
		computed: {
			filteredData() {
				if (this.search === "")
					return this.data;
				
				return this.data.filter(row => {
					return row.name.toLowerCase().includes(this.search.toLowerCase()) ||
						row.token_addr.includes(this.search) ||
						row.token_addr.includes(this.search) ||
						`${row.spawn_chunk_x},${row.spawn_chunk_y}`.includes(this.search) ||
						(this.rooms[row.target_oasis_id] && this.rooms[row.target_oasis_id].name.toLowerCase().includes(this.search.toLowerCase()))
				})
			}
		},
		methods: {
			getTableData: function () {
				User.indexBuildings(this.$store.state.wallet_addr).then((r) => this.data = r.data);
			},
			getUserRooms: function () {
				User.indexRooms(this.$store.state.wallet_addr).then((r) => {
					for (let i = 0; i < r.data.length; i++) {
						this.rooms[r.data[i].token_id] = r.data[i];
					}
				});
			},
		},
		mounted() {
			this.getTableData();
			this.getUserRooms();
		}
	}
</script>

<style scoped>
	
	table {
		color: white;
	}
	
	thead {
		font-weight: bold;
	}
	
	.btn + .btn:last-child {
		margin-left: 0;
	}
	
	td {
		vertical-align: middle;
	}
	
	.table-preview {
		max-height: 60px;
	}
	
	.oasis-link {
		color: #26b6d4;
	}
	
	.img-td {
		padding: 7px 0 0;
	}
</style>